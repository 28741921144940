import React from 'react';

const Circle: React.FC<{ size?: 'big' | 'small' }> =
    ({ size = 'big', children }) => {
        const length = size === 'big' ? 42 : 33;
        const offset = length / 2 - 1;
        return <svg focusable="false" width={`${length}px`} height={`${length}px`} viewBox={`-1 -1 ${length} ${length}`} style={{ display: 'block' }}>
            <g>
                <circle stroke="currentColor" fill="none" cx={offset} cy={offset} r={offset} />
                {children}
            </g>
        </svg>;
    }

export default Circle;