/* Home and HomeSlash account for the differences between dev and production */
export enum Routes {
    Book = '/book',
    ReservationEnd = '/ReservationEnd',
    Confirmation = '/confirmation',
    ChangePassword = '/Account/ChangePassword',
    ForgotPassword = '/Account/ForgotPassword',
    Login = '/Account/Login',
    Home = '',
    HomeSlash = '/',
    ModifyReservation = '/Account/ModifyReservation',
    PasswordReset = '/Account/PasswordReset',
    PreReservation = '/PreReserve',
    Register = '/Account/Register',
    UserReservations = '/Account/UserReservations',
    UserReservationsByEmail = '/Account/UserReservationsByEmail',
    UserDetails = '/Account/UserDetails',
    OverviewCalendar = '/OverviewCalendar',
    UnsuccessfulPayment = '/UnsuccessfulPayment',
    PaymentProcessing = '/PaymentProcessing',
    PaymentProcessPending = '/PaymentProcessPending'
}