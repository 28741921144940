import Moment from 'moment';
import { AppLocale } from 'src/entities/modules';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import validatorIsNumeric from 'validator/lib/isNumeric';
import validatorIsEmpty from 'validator/lib/isEmpty';
import validatorIsEmail from 'validator/lib/isEmail';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { IQuestion, QuestionType, QuestionFormatType } from 'src/entities/question';
import globalMessages from '../globalMessages';

const messages = defineMessages({
    validationSame: {
        id: 'validationSame',
        defaultMessage: 'A két érték nem egyezik meg.'
    },
    validationLength: {
        id: 'validationLength',
        defaultMessage:
            `{type, select, 
            min{Minimum karakterszám: {min}}
            max{Maximum karakterszám: {max}}
            minmax{Minimum karakterszám: {min}, maximum karakterszám: {max}}
            other{Kötelező karakterszám: {min}}
        }`
    },
    validationAlpha: {
        id: 'validationAlpha',
        defaultMessage: 'Nem tartalmazhat számjegyeket.'
    },
    validationNumeric: {
        id: 'validationNumeric',
        defaultMessage: 'Csak számjegyeket tartalmazhat.'
    },
    validationDate: {
        id: 'validationDate',
        defaultMessage: 'A dátum formátuma nem megfelelő (helyes formátum: éééé-hh-nn).'
    },
    validationInterval: {
        id: 'validationInterval',
        defaultMessage: 'Legkorábbi dátum: {min}, legkésőbbi dátum: {max}'
    },
    validationSpecialChars: {
        id: 'validationSpecialChars',
        defaultMessage: 'A következő karakterek nem megengedettek: {bannedChars}'
    }
})

export interface IValidationRule {
    field: string,
    otherField?: string,
    method: (...args: any) => boolean,
    args?: any,
    message: MessageDescriptor;
    messageValues?: { [key: string]: any };
}

export const required = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isNotEmpty,
        message: globalMessages.validationRequired
    }
}

export const emailFormat = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isValidEmail,
        message: globalMessages.validationEmailFormat
    }
}

export const phoneFormat = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isValidPhoneNumber,
        message: globalMessages.validationPhoneFormat
    }
}

export const isChecked = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isTrue,
        message: globalMessages.validationRequired
    }
}

export const ifFalseThenRequired = (fieldName: string, otherFieldName: string) => {
    return {
        field: fieldName,
        otherField: otherFieldName,
        method: falseCheckRequired,
        message: globalMessages.validationRequired
    }
}

export const isEqual = (fieldName: string, otherFieldName: string): IValidationRule => {
    return {
        field: fieldName,
        otherField: otherFieldName,
        method: equals,
        args: otherFieldName,
        message: messages.validationSame
    }
}

export const isValidLength = (fieldName: string, min?: number, max?: number): IValidationRule => {
    return {
        field: fieldName,
        method: length,
        args: [min, max],
        message: messages.validationLength,
        messageValues: {
            type:
                min && min === max && 'other' ||
                min && max && 'minmax' ||
                min && 'min' ||
                max && 'max',
            min, max
        }
    }
}

export const noSpecialChars = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: checkSpecialChars,
        message: messages.validationSpecialChars,
        messageValues: {
            bannedChars: bannedChars.join(' ')
        }
    }
}

export const validDate = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isValidDate,
        message: messages.validationDate
    }
}

const isValidDate = (value: string) =>
    !value || Moment(value).isValid();


export const yearOffset = (fieldName: string, [min, max]: [number, number]): IValidationRule => {
    return {
        field: fieldName,
        method: isBetweenYearOffsets,
        args: [[min, max]],
        message: messages.validationInterval,
        messageValues: {
            min: Moment().add(min, 'years').set({ 'month': 0, 'date': 1 }).format('YYYY-MM-DD'),
            max: Moment().add(max, 'years').set({ 'month': 11, 'date': 31 }).format('YYYY-MM-DD')
        }
    }
}

const locales = {
    [AppLocale.Hu]: 'hu-HU',
    [AppLocale.HuFormal]: 'hu-HU',
    [AppLocale.De]: 'de-DE',
    [AppLocale.DeFormal]: 'de-DE',
    [AppLocale.Sk]: 'sk-SK',
    [AppLocale.En]: 'en-IN'
}

export const isAlpha = (fieldName: string, locale: AppLocale): IValidationRule => {
    return {
        field: fieldName,
        method: isAlphaTest,
        args: locales[locale],
        message: messages.validationAlpha
    }
}

export const isNumeric = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isNumericTest,
        message: messages.validationNumeric
    }
}

export const requiredBoolean = (fieldName: string): IValidationRule => {
    return {
        field: fieldName,
        method: isTrue,
        message: globalMessages.validationRequired
    }
}

const isBetweenYearOffsets = (date: string, otherValue: string, [min, max]: [number, number]) => {
    if (!date) { return true; }
    const year = Moment(date).year();
    return (
        (year >= min + Moment().year()) &&
        (year <= max + Moment().year())
    );
}

const isAlphaTest = (value: string) =>
    !/\d/.test(value)

const isNumericTest = (value: string) =>
    !value || validatorIsNumeric(value)

const isNotEmpty = (value: string): boolean => {
    return !validatorIsEmpty(value);
}

const isValidEmail = (value: string): boolean => {
    return validatorIsEmpty(value) || validatorIsEmail(value, { allow_utf8_local_part: true });
}

const isValidPhoneNumber = (phoneNumber: string): boolean => {
    if (validatorIsEmpty(phoneNumber)) {
        return true;
    }

    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (parsedNumber) {
        return parsedNumber.isValid();
    }

    return false;
}

const isTrue = (value: string): boolean => {
    return value.toLowerCase() === 'true';
}

const equals = (value: string, otherValue: string): boolean => {
    return value === otherValue;
}

const length = (value: string, otherValue: string, min?: number, max?: number): boolean =>
    !value ||
    (!min || value.length >= min) &&
    (!max || value.length <= max);

const bannedChars: string[] = ['{', '}', '|', ':='];

const checkSpecialChars = (value: string): boolean => {
    return !bannedChars.some(char => value.includes(char))
}

const falseCheckRequired = (value: string, othervalue: string) => {
    if (!isTrue(othervalue)) {
        return isNotEmpty(value);
    }
    return true;
}

export const createValidationRules = (questions: IQuestion[], locale: AppLocale): IValidationRule[] => {
    return questions.reduce((acc, q) => {
        const id = String(q.Id);
        if (q.Required) {
            if (q.Type === QuestionType.YesNo) {
                acc.push(requiredBoolean(id))
            }
            else {
                acc.push(required(id));
            }
        }

        if (q.Format === QuestionFormatType.Alpha) {
            acc.push(isAlpha(id, locale));
        }

        if (q.Format === QuestionFormatType.Numeric) {
            acc.push(isNumeric(id));
        }

        if (q.Type === QuestionType.Date) {
            acc.push(validDate(id))
            acc.push(yearOffset(id, [q.MinimumValue, q.MaximumValue]));
        }

        if (q.Type === QuestionType.FreeText) {
            acc.push(isValidLength(id, q.MinimumValue, q.MaximumValue));
            acc.push(noSpecialChars(id));
        }

        return acc;
    }, [] as IValidationRule[])
}