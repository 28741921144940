import React from 'react';
import classNames from 'classnames';

interface IPlaceholderProps {
    count?: number;
}

export const Bone: React.FC<{ className?: string }> = ({ className, children }) =>
    <div className={classNames('bone', className)} role="presentation">
        <div className="bone__marrow">{children}</div>
    </div>

export const TextSkeleton: React.FC = ({ children }) =>
    <Bone className="text-skeleton">
        {children}
    </Bone>

const Placeholder: React.FunctionComponent<IPlaceholderProps> = ({ count = 5 }) => {
    const arrayFromLength = Array.from(Array(count));
    const isLast = (index: number) => arrayFromLength.length > 1 && arrayFromLength.length === (index + 1);

    return (
        <div className="placeholder">
            {arrayFromLength.map((_, i: number) =>
                <div
                    key={i}
                    className={classNames('text-skeleton bone placeholder__line', { 'placeholder__line--last': isLast(i) })}
                />
            )}
        </div>
    );
};

export const ParagraphSkeleton
    : React.FC<{ lines: number }> =
    ({ lines }) => <Placeholder count={lines} />;

export const PagePlaceholder = () =>
    <div className="App__page-placeholder">
        <Placeholder />
    </div>

export default Placeholder;
