import { MessageDescriptor } from 'react-intl';

export interface IPaymentSelectOption {
    Id: number;
    Name: string;
    PaymentProvider?: PaymentProvider;
    NeedsInvoice: boolean;
    DisplayName?: MessageDescriptor;
}

export interface IPocket extends IPaymentSelectOption {
    IsActive: boolean;
    Type: PocketType;
    BarionPixelId: string | null;
}

export interface IProviderMessage {
  name: string;
  displayName?: MessageDescriptor ;
}

/* eslint-disable no-bitwise */
export enum PocketType {
    Default = 1 << 0,
    Online = 1 << 1,
    Cash = 1 << 2,
    Other = 1 << 3
}
/* eslint-enable no-bitwise */

export enum PaymentProvider {
    NoProvider = -1,
    Barion = 1,
    Paypal = 2
}