import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ICompany } from '../../entities/company';
import { Routes } from '../../routes';
import HeroSection from '../HeroSection/HeroSection';
import ProfileButton from '../Header/ProfileButton';

interface IRootProps {
    isEmbedded: boolean;
    hideUser: boolean;
    company: ICompany | null;
}

const Root: React.FC<IRootProps> = ({ isEmbedded, hideUser, company }) => {
    const location = useLocation();
    const isHomeRoute = location.pathname === Routes.Home;
    const isHomeSlashRoute = location.pathname === Routes.HomeSlash;

    return (
        <>
            {isEmbedded && !hideUser && (
                <ProfileButton isEmbedded={true} logOut={() => null} toggleLogin={() => null} />
            )}
            {!isEmbedded && company && (isHomeRoute || isHomeSlashRoute) && (
                <HeroSection company={company} />
            )}
            <div className="App__container-inner">
                <Outlet />
            </div>
        </>
    );
};

export default Root;
