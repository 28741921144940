import { FormattedMessage, defineMessages, MessageDescriptor } from 'react-intl';
import React from 'react';

export type IAvailabilityWarningType = 'bookingNotAvailable' | 'noFreeIntervals' | 'noFreeIntervalsOnDay' | 'classicFE' | 'inactiveFE';

export type IAvailabilityWarningModifier = 'app-level' | 'color-red' | 'center';

const messages = defineMessages<IAvailabilityWarningType, MessageDescriptor>({
    bookingNotAvailable: {
        id: 'bookingNotAvailable',
        defaultMessage: 'Az online foglalás még nem elérhető.'
    },
    noFreeIntervals: {
        id: 'noFreeIntervals',
        defaultMessage: 'Nincs foglalható időpont.'
    },
    noFreeIntervalsOnDay: {
        id: 'noFreeIntervalsOnDay',
        defaultMessage: 'Nincs foglalható időpont ezen a napon.'
    },
    classicFE: {
        id: 'classicFE',
        defaultMessage: 'Az időpontfoglaló ezen a felületen nem elérhető.'
    },
    inactiveFE: {
        id: 'inactiveFE',
        defaultMessage: 'Sajnáljuk, ez az időpontfoglaló jelenleg inaktív.'
    }
});

const AvailabilityWarning: React.FunctionComponent<
    { type: IAvailabilityWarningType, modifier?: IAvailabilityWarningModifier }> = ({ type, modifier }) => {
        return (
            <div className={`availability-warning ${modifier ? `availability-warning--${modifier}` : ''}`}>
                <FormattedMessage {...messages[type]} />
            </div>
        )
    }

export default AvailabilityWarning