import { createContext, useContext } from 'react';
import { IJsApiQuestion } from '../../entities/question';

export interface IJsApiContext {
    data: IJsApiQuestion[];
    get: () => IJsApiQuestion[]; 
    setData: React.Dispatch<React.SetStateAction<IJsApiQuestion[]>>;
    removeEmptyValueFromLists: () => void;
    setQuestionToHidden: (id: number, isHidden: boolean) => void;
    setDefaultValue: (id: number, value: string) => void;
    setRequired: (id: number, isRequired: boolean) => void;
    setQuestionValue: (id: number, value: string) => void;
    cleanQuestionValue: (id: number) => void;
}

const JsApiContext = createContext<IJsApiContext>({
    data: [] as IJsApiQuestion[],
    get: () => [], 
    setData: () => undefined,
    removeEmptyValueFromLists: () => undefined,
    setQuestionToHidden: () => undefined,
    setDefaultValue: () => undefined,
    setRequired: () => undefined,
    setQuestionValue: () => undefined,
    cleanQuestionValue: () => undefined
});

export const useJsApiContext = () => {
  return useContext(JsApiContext);
};

export default JsApiContext;