import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import { unregister } from './registerServiceWorker';
import ErrorBoundary from './components/ErrorBoundary';
import { IntlProvider } from './components/Translation/IntlProviderWrapper';
import IntlPolyfill from 'react-intl-polyfill';

ReactDOM.render(
    <IntlPolyfill locales={['en', 'de', 'hu', 'sk']}>
        <IntlProvider>
            <ErrorBoundary>
                <App />
            </ErrorBoundary>
        </IntlProvider>
    </IntlPolyfill>,
    document.getElementById('root') as HTMLElement
);

unregister();
