import { IAuthInfo } from './../../entities/authorization';
import { IDTO, ILoginErrorDto } from 'src/entities/common';
import { fetchToken, accessToken } from './authorization';
import { IPasswordResetRequest, IForgotPasswordRequest, IChangePasswordRequest, IUserInvoice } from './../../entities/user';
import { IUser, IUserCredentials, ISignupUser } from './../../entities/user';
import fetcher, { ContentType } from '../fetcher';
import { IResponseDto } from 'src/entities/common';
import { handleTokenError, handleError } from './errorHandler';

export const login = async (credentials: IUserCredentials): Promise<IUser | null> => {
    return await fetchToken(credentials).then((authInfo) => {
        return getUserWithInvoice(authInfo);
    }, (errorDto: ILoginErrorDto) => {
        return Promise.reject(handleTokenError(errorDto));
    });
};

export const getUser = async (authInfo?: IAuthInfo): Promise<IUser | null> => {
    const token = authInfo?.access_token || await accessToken();
    if (!token) {
        return null;
    }

    return fetcher.get<IDTO<IUser>>('/Users', token)
        .then(response => (response.Data));
}

export const getUserWithInvoice = async (authInfo?: IAuthInfo): Promise<IUser | null> => {
    const token = authInfo?.access_token || await accessToken();
    if (!token) {
        return null;
    }

    return fetcher.get<IDTO<IUser>>('/Users/WithInvoiceDetails', token)
        .then(response => {
            if (response.Data.InvoiceDetails) {
                response.Data.InvoiceDetails.Country = 'HU';
            }
            return response.Data;
        });
}

export const postInvoice = async (invoiceDetails: IUserInvoice): Promise<boolean> => {
    const token = await accessToken();
    if (!token) {
        return false;
    }

    return fetcher.put<IDTO<IUser>>('/Users/InvoiceDetails', invoiceDetails, ContentType.Json, token)
        .then(response => {
            return response.Success
        }, (errorDto: IResponseDto) => {
            return Promise.reject(handleError(errorDto));
        });
}

export const postUserDetails = async (userDetails: any): Promise<boolean> => {
    const token = await accessToken();
    if (!token) {
        return false;
    }

    return await fetcher.post<IResponseDto>('/Users', userDetails, ContentType.Json, token)
        .then(response => {
            return response.Success
        }, (errorDto: IResponseDto) => {
            return Promise.reject(handleError(errorDto));
        });
}

export const signUp = async (userDetails: ISignupUser): Promise<void> => {
    await fetcher.post<IResponseDto>(`/Users/Signup`, userDetails)
        .catch((errorDto: IResponseDto) => {
            return Promise.reject(handleError(errorDto));
        });
}

export const requestNewPassword = async (model: IForgotPasswordRequest) => {
    return fetcher.post<IResponseDto>('/Users/ForgotPassword', model)
        .then(() => true, (errorDto: IResponseDto) => {
            return Promise.reject(handleError(errorDto));
        })
}

export const resetPassword = async (model: IPasswordResetRequest) => {
    return fetcher.post<IResponseDto>('/Users/PasswordReset', model)
        .then(() => undefined,
            (errorDto: IResponseDto) => {
                return Promise.reject(handleError(errorDto));
            });
}

export const changePassword = async (model: IChangePasswordRequest): Promise<undefined> => {
    const token = await accessToken();
    if (!token) {
        return;
    }

    return fetcher.post<IResponseDto>('/Users/ChangePassword', model, ContentType.Json, token)
        .then(() => undefined, (errorDto: IResponseDto) => {
            return Promise.reject(handleError(errorDto));
        });
}