import React from 'react';
import { useIntl } from 'react-intl';
import { ICompany } from '../../entities/company';
import { TextSkeleton } from '../Placeholder/Placeholder';
import { Routes } from 'src/routes';
import { HashLink as Link } from 'react-router-hash-link';
import { viewTypeMessages } from 'src/services/globalMessages';
import { useState } from 'react';
import { useKeyboardFocusIndicator } from 'src/hooks';
import Select from '../form-components/Select/Select';
import { isUserElevated } from 'src/services/api/authorization';
import HeaderMenu from './Menu/HeaderMenu';
import config, { DEV_ENV } from '../../constants';
import { Navbar, NavbarToggler } from 'reactstrap';

export const adminLocation = process.env.NODE_ENV === DEV_ENV ? config.MVC_URL + '/Admin' : window.location.origin + '/Admin';

interface IHeaderProps {
    company: ICompany | null;
    hasContactInfo: boolean;
}

const viewOptions = Object.values(viewTypeMessages);

const AppViewSwitcher = () => {

    const intl = useIntl();

    const handleChange = () => {
        window.location.href = adminLocation;
    }

    return <Select
        className="header__app-view-switcher"
        values={
            viewOptions.map(view => (
                {
                    value: view.id,
                    label: intl.formatMessage(view)
                }
            ))}
        value="viewClient"
        onChange={handleChange}
    />
}

const Header: React.FunctionComponent<IHeaderProps> = ({ company, hasContactInfo }) => {
    useKeyboardFocusIndicator();

    const Title = () =>
        company
            ? <Link to={Routes.HomeSlash} className={`header__title ${isUserElevated() && 'header__title--hidden-mobile'}`}>{company.Name}</Link>
            : <TextSkeleton>{'Skeleton Company Name'}</TextSkeleton>

    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => setIsOpen(!isOpen);

    return (
        <header id="app-header" className="header">
            <Navbar className="header__nav-bar" light={true} expand="md" container={false}>
                <div className="header__lateral">
                    <Title />
                    {isUserElevated() && company && <AppViewSwitcher />}
                    <NavbarToggler className="header__navbar-toggle" onClick={toggleMenu} />
                </div>
                <HeaderMenu isOpen={isOpen} company={company} hasContactInfo={hasContactInfo} toggleMenu={toggleMenu} />
            </Navbar>
        </header>
    );
}

export default Header;
