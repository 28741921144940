/* eslint-disable no-underscore-dangle */
import { IResponseDto, ILoginErrorDto } from './../entities/common';
import config from '../constants';

export enum ContentType {
    Json = 'application/json',
    Form = 'application/x-www-form-urlencoded'
}

export const assertError = async (response: Response, reject: (e: any) => void) => {
    let responseBody: IResponseDto | ILoginErrorDto | null = null;
    try {
        responseBody = await response.json();
    } catch (error) {
        return reject(new Error(`Bad response format: ${error.message}`));
    }

    if (responseBody) {
        if ('Success' in responseBody) {
            if (!responseBody.Success && (responseBody.Error || responseBody.ValidationErrors.length)) {
                return reject(responseBody);
            }
        }
        else if ('error_description' in responseBody) {
            return reject(responseBody);
        }
    }

    return reject(new Error('Oops something went wrong: Unhandled Exception'));
}

export async function handleResponse<T>(response: Response, resolve: (value?: T | PromiseLike<T>) => void, reject: (e: any) => void) {
    let res;
    if (response.ok) {
        try {
            res = await response.json();
        }
        catch {
            res = undefined;
        }
        resolve(res);
    } else {
        await assertError(response, reject);
    }
}

const fetcher = {
    _companyBaseUrl: config.BASE_API_URL,
    set companyBaseUrl(value: string) {
        this._companyBaseUrl = value + config.BASE_API_URL;
    },
    get companyBaseUrl(): string {
        return this._companyBaseUrl;
    },
    post<T>(url: string, data: object, contentType: ContentType = ContentType.Json, token: string | null = null): Promise<T> {
        let bodyData;

        if (contentType === ContentType.Json) {
            bodyData = JSON.stringify(data);
        } else if (contentType === ContentType.Form) {
            const params = new URLSearchParams(data as Record<string, string>);
            bodyData = params.toString();
        }

        const customHeaders = new Headers();
        customHeaders.set('Content-Type', contentType);
        if (token) {
            customHeaders.set('Authorization', 'Bearer ' + token);
        }

        const httpHeaders: RequestInit = {
            method: 'POST',
            body: bodyData,
            credentials: 'include',
            headers: customHeaders
        };

        return new Promise<T>((resolve, reject) => {
            fetch(this.companyBaseUrl + url, httpHeaders).then((response) => {
                handleResponse(response, resolve, reject);
            }).catch((error: Error) => reject(new Error(`Oops something went wrong: ${error}`)));
        });
    },
    put<T>(url: string, data: object, contentType: ContentType = ContentType.Json, token: string | null = null): Promise<T> {
        let bodyData;

        if (contentType === ContentType.Json) {
            bodyData = JSON.stringify(data);
        } else if (contentType === ContentType.Form) {
            const params = new URLSearchParams(data as Record<string, string>);
            bodyData = params.toString();
        }

        const customHeaders = new Headers();
        customHeaders.set('Content-Type', contentType);
        if (token) {
            customHeaders.set('Authorization', 'Bearer ' + token);
        }

        const httpHeaders: RequestInit = {
            method: 'PUT',
            body: bodyData,
            credentials: 'include',
            headers: customHeaders
        };

        return new Promise<T>((resolve, reject) => {
            fetch(this.companyBaseUrl + url, httpHeaders).then((response: Response) => {
                handleResponse(response, resolve, reject);
            }).catch((error: Error) => reject(new Error(`Oops something went wrong: ${error}`)));
        });
    },
    delete<T>(url: string, token: string | null = null): Promise<T> {
        const customHeaders = new Headers();
        if (token) {
            customHeaders.set('Authorization', 'Bearer ' + token);
        }

        const httpHeaders: RequestInit = {
            method: 'DELETE',
            credentials: 'include',
            headers: customHeaders
        };

        return new Promise<T>((resolve, reject) => {
            fetch(this.companyBaseUrl + url, httpHeaders).then((response: Response) => {
                handleResponse(response, resolve, reject);
            }).catch((error: Error) => reject(new Error(`Oops something went wrong: ${error}`)));
        });
    },
    get<T>(url: string, token?: string): Promise<T> {
        const httpHeaders: RequestInit = {
            method: 'GET',
            credentials: 'include'
        };

        if (token) {
            httpHeaders.headers = {
                'Authorization': 'Bearer ' + token
            }
        }

        return new Promise<T>((resolve, reject) => {
            fetch(this.companyBaseUrl + url, httpHeaders).then((response: Response) => {
                handleResponse(response, resolve, reject);
            }).catch((error: Error) => reject(new Error(`Oops something went wrong: ${error}`)));
        });
    }
};

export default fetcher;
