import { ReservationOrder } from './entities/company';
import { ModuleSearchParam } from './entities/modules';

export enum EmbedModeType {
    WebPage = 'webpage',
    List = 'list',
    ProgressBar = 'progress_bar'
}

export enum OverviewCalendarType {
    Timeline = 'timeline',
    Grid = 'grid',
}

export interface IEmbedOptions {
    order: ReservationOrder | null;
    mode: EmbedModeType;
    hideFooter: boolean;
    hideUser: boolean;
    hideCompanyInfo: boolean;
    calendarIds: number[] | null;
    overviewType: OverviewCalendarType | null;
}

export const defaultEmbedOptions : IEmbedOptions = {
    order: null,
    mode: EmbedModeType.WebPage,
    hideFooter: false,
    hideUser: false,
    hideCompanyInfo: false,
    calendarIds: null,
    overviewType: OverviewCalendarType.Grid
}

/**
 * @returns {boolean} false on values 'false', '0', '' and null (case-insensitive)
 */
const parseBooleanSearchParam = (b: string | null) => {
    return !!b && !/^(false|0)$/i.test(b);
}

/**
 * @returns mode if it's a valid mode type, Webpage otherwise
 */
const parseMode = (mode: string | null): EmbedModeType  => {
    if (!mode || !Object.values<string>(EmbedModeType).includes(mode)) {
        return EmbedModeType.WebPage;
    }
    return mode as EmbedModeType;
}

const parseOrder = (calendarId: string | null, serviceId: string | null): ReservationOrder | null => {
    if (calendarId === null && serviceId !== null) {
        return ReservationOrder.ServiceFirst;
    } else if (calendarId !== null && serviceId === null) {
        return ReservationOrder.CalendarFirst;
    } else {
        return null;
    }
}

const parseIdList = (ids: string | null): number[] | null => {
    if (ids) {
        return ids.split(';').map(id => +id);
    }
    return null;
}

const parseCalendarView = (overviewType: string | null): OverviewCalendarType | null => {
    const isExistingView = overviewType && Object.values<string>(OverviewCalendarType).includes(overviewType);
    return isExistingView ? overviewType as OverviewCalendarType : null;
}

export const readEmbedOptions = (): IEmbedOptions => {
    const params = new URLSearchParams(window.location.search);

    return {
        order: parseOrder(params.get(ModuleSearchParam.CalendarId), params.get(ModuleSearchParam.ServiceId)),
        mode: parseMode(params.get('mode')),
        hideFooter: parseBooleanSearchParam(params.get('footer')),
        hideUser: parseBooleanSearchParam(params.get('HideUser')),
        hideCompanyInfo: parseBooleanSearchParam(params.get('HideCompanyInfo')),
        calendarIds: parseIdList(params.get('CalendarIds')),
        overviewType: parseCalendarView(params.get('overviewType')),
    };
}