import React from 'react';

interface ICheckboxProps {
    fieldName: string;
    onChange: (value: boolean, field: string) => void;
    checked?: boolean;
    required?: boolean;
    className?: string;
}

class Checkbox extends React.Component<ICheckboxProps, object> {
    public static defaultProps: Pick<ICheckboxProps, 'fieldName'> = {
        fieldName: ''
    };

    public render() {
        return (
            <>
                <label className={`checkbox ${this.props.className || ''}`}>
                    {this.props.required && <span className="question__required">* </span>}
                    {this.props.children}
                    <input type="checkbox" onChange={this.handleInputChange} checked={this.props.checked} required={this.props.required} />
                    <span className="checkbox__checkmark" />
                </label>
            </>
        );
    }

    private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(event.target.checked, this.props.fieldName);
    }
}

export default Checkbox;
