import { companyResponse } from './../../test-mocks/mock-api/companyResponse';
import { ICompany, IContactInfo, ICustomCssAndJs, IDataPolicy, ITrackingCodes } from '../../entities/company';
import fetcher from '../fetcher';
import { IDTO } from 'src/entities/common';
import { IPocket } from 'src/entities/pocket';

export const getCompanyData = (): Promise<ICompany> => {
    // FIXME: Remove mock data if API endpoint will have final data model
    return fetcher.get<IDTO<ICompany>>('/Company')
        .then(respJson => ({ ...companyResponse.Data, ...respJson.Data }));
};

export const postLanguage = (language: string): Promise<any> => {
    return fetcher.post<any>(`/Company/SetLanguage?lang=${language}`, {});
}

export const getDataPolicy = async (language: string): Promise<IDataPolicy> => {
    return fetcher.get<IDTO<IDataPolicy>>(`/Company/PrivacyStatement?lang=${language}`)
        .then(respJson => respJson.Data);
}

export const getContactInfo = async (): Promise<IContactInfo> => {
    return fetcher.get<IDTO<IContactInfo>>('/Company/ContactInfo')
        .then(respJson => respJson.Data);
}

export const getTrackingCodes = async () => {
    return fetcher.get<IDTO<ITrackingCodes>>('/Company/TrackingCodes')
        .then(respJson => respJson.Data);
}

export const getCustomStyleSheetAndScript = async () => {
    return fetcher.get<IDTO<ICustomCssAndJs>>('/Company/CustomScripts')
        .then(respJson => respJson.Data);
}

export const getActivePockets = async () => {
    return fetcher.get<IDTO<IPocket[]>>('/Company/Pocket/Active')
        .then(respJson => respJson.Data || []);
}