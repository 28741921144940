import React from 'react';
import Helmet from 'react-helmet';

interface IBarionPixelProps {
    barionPixelId: string;
}

export const BarionPixel: React.FunctionComponent<IBarionPixelProps> = ({ barionPixelId }) => {
    return <Helmet>
        <script type="text/javascript">{`
    // Create BP element on the window
    window["bp"] = window["bp"] || function () {
        (window["bp"].q = window["bp"].q || []).push(arguments);
    };
    window["bp"].l = 1 * new Date();

    // Insert a script tag on the top of the head to load bp.js
    scriptElement = document.createElement("script");
    firstScript = document.getElementsByTagName("script")[0];
    scriptElement.async = true;
    scriptElement.src = 'https://pixel.barion.com/bp.js';
    firstScript.parentNode.insertBefore(scriptElement, firstScript);
    window['barion_pixel_id'] = '${barionPixelId}';            

    // Send init event
    bp('init', 'addBarionPixelId', window['barion_pixel_id']);
        `}</script>
    </Helmet>;
}