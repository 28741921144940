import React from 'react';
import Helmet from 'react-helmet';

export interface ICustomScriptProps {
    scriptText: string | null;
}

export const CustomScript: React.FunctionComponent<ICustomScriptProps> = ({ scriptText }) => {
    if (!scriptText) {
        return null;
    }

    return <Helmet>
        {scriptText.includes('<script')
            ? scriptText
            : <script type="text/javascript">
                {scriptText}
            </script>
        }
    </Helmet>
}