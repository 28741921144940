import React from 'react';
import { ICompany } from '../../entities/company';
import objectFitImages from 'object-fit-images';
import classNames from 'classnames';
import Nl2br from '../Nl2br/Nl2br';

interface IHeroSectionProps {
    company: ICompany;
}

interface IHeroSectionState {
    hasDescription: boolean;
    hasBackground: boolean;
    hasLogo: boolean;
}

class HeroSection extends React.Component<IHeroSectionProps, IHeroSectionState> {

    private imgRef = React.createRef<HTMLImageElement>();

    constructor(props: IHeroSectionProps) {
        super(props);
        const { company } = props;
        this.state = {
            hasDescription: !!company.ShortDescription,
            hasBackground: !!company.BackgroundLink,
            hasLogo: !!company.LogoLink
        }
    }

    public componentDidMount() {
        if (this.imgRef.current) { objectFitImages(this.imgRef.current); }
    }

    public render() {
        const { company } = this.props;
        const { hasLogo, hasBackground, hasDescription } = this.state;

        const detailsClasses = classNames(
            'hero-section__details',
            { 'hero-section__details--has-background': this.state.hasBackground }
        );

        return (
            <section className="hero-section" >
                {hasBackground &&
                    <img
                        ref={this.imgRef}
                        className="hero-section__background"
                        src={company.BackgroundLink}
                        alt=""
                        onError={this.disableBackground}
                    />}
                {hasLogo &&
                    <div className={detailsClasses}>
                        <img
                            className="hero-section__logo"
                            src={company.LogoLink}
                            alt=""
                            onError={() => this.disableLogo()} />
                    </div>
                }
                {hasDescription &&
                    <article className="hero-section__description">
                        <Nl2br text={company.ShortDescription} />
                    </article>
                }
            </section>
        );
    }

    private disableBackground = () => {
        this.setState({
            hasBackground: false
        });
    }

    private disableLogo = () => {
        this.setState({
            hasLogo: false
        });
    }
}

export default HeroSection;
