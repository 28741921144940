'use strict';

if (typeof Promise === 'undefined') {
    // Rejection tracking prevents a common issue where React gets into an
    // inconsistent state due to an error, but it gets swallowed by a Promise,
    // and the user has no idea what causes React's erratic future behavior.
    require('promise/lib/rejection-tracking').enable();
    window.Promise = require('promise/lib/es6-extensions.js');
}

// fetch() polyfill for making API calls.
require('whatwg-fetch');
require('core-js/features/array');
require('core-js/features/map');
require('core-js/features/set');
require('core-js/features/weak-map');
require('core-js/features/string/starts-with');
require('core-js/features/array/includes');
require('core-js/features/array/for-each');
require('core-js/features/string/includes');
require('core-js/features/object/values');
require('core-js/features/object/entries');
require('core-js/features/object/from-entries');
require('core-js/features/array/flat');
require('core-js/features/dom-collections/for-each')
require('core-js/features/promise/finally')
require('core-js/features/array/from');
require('core-js/features/array/find-index');
require('core-js/features/object/assign');
require('core-js/features/promise');
require('core-js/features/string/ends-with');
require('core-js/features/symbol/for');
require('core-js/features/weak-set');

require('url-search-params-polyfill');

// Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.
Object.assign = require('object-assign');

if (process.env.NODE_ENV !== 'test') {
    // CSS variables polyfill for legacy browsers
    const cssVars = require('css-vars-ponyfill').default;
    cssVars({exclude: '.App style', rootElement: document.head});
}

// In tests, polyfill requestAnimationFrame since jsdom doesn't provide it yet.
// We don't polyfill it in the browser--this is user's responsibility.
if (process.env.NODE_ENV === 'test') {
    require('raf').polyfill(global);
}

require('intl-pluralrules');
require('mdn-polyfills/Element.prototype.toggleAttribute');


if (process.env.NODE_ENV !== 'test') {
    const focusWithin = require('focus-within').default;
    focusWithin(document);
}

require('./timezone-polyfill/date-time-format-timezone-no-data-min.js')
require('./timezone-polyfill/metazone.js')
require('./timezone-polyfill/tzdata-europe-berlin.js')
require('./timezone-polyfill/locale-de.js')
require('./timezone-polyfill/locale-hu.js')
require('./timezone-polyfill/locale-sk.js')
require('./timezone-polyfill/locale-en.js')