// Used: https://github.com/mikeries/react-validation-tutorial

import { IValidationRule } from './validations/validations';
import { MessageDescriptor } from 'react-intl';

interface IFieldValidation {
    [key: string]: {
        isInvalid: boolean,
        message?: MessageDescriptor,
        messageValues?: { [key: string]: any };
    }
}

export interface IValidation {
    isValid: boolean;
    fields: IFieldValidation
}

export interface IValidatable {
    validation: IValidation
}

class FormValidator {
    constructor(private rules: IValidationRule[]) { }

    public validate = (state: any): IValidation =>
        this.rules.reduce((validation, rule) => {
            if (!validation.fields[rule.field].isInvalid) {
                const fieldValue = state[rule.field] != null ? state[rule.field].toString() : '';
                const args = rule.args || [];
                const otherFieldValue = rule.otherField && state[rule.otherField].toString();

                if (!rule.method(fieldValue, otherFieldValue, ...args)) {
                    validation.fields[rule.field] = { isInvalid: true, message: rule.message, messageValues: rule.messageValues }
                    validation.isValid = false;
                }
            }

            return validation;
        }, this.getInitial());

    public getInitial = (): IValidation => ({
        isValid: true,
        fields: this.rules.reduce((fields, { field }) => (
            { ...fields, [field]: { isInvalid: false } }
        ), {})
    })
}

export default FormValidator;