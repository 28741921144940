/**
 * Inserts line break elements into a body of text sectioned by newlines
 */

import React from 'react';

interface INl2brProps {
    text: string;
}

const Nl2br: React.FunctionComponent<INl2brProps> = props => {
    return (
        <>
            {props.text && props.text.split('\n').map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {item}
                        <br />
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default Nl2br;
