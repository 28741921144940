import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Booked4us from '../icons/Booked4us';
import config from 'src/constants';

const messages = defineMessages({
    getBooked4Us: {
        id: 'getBooked4Us',
        defaultMessage: 'Tetszik a foglalási rendszerünk? Indítsd el saját időpontfoglaló naptáradat!'
    }
});

interface IFooterProps {
    isEmbedded: boolean;
    hasActiveBarionPocket: boolean;
}

const Footer: React.FunctionComponent<IFooterProps> = ({ isEmbedded, hasActiveBarionPocket }) =>
    <>
        {hasActiveBarionPocket &&
            <div className='barion-logo'>
                <img className='barion-logo__image'
                    src={'https://d1ursyhqs5x9h1.cloudfront.net/sw/images/Barion-smart-payment-horizontal-whitebg.png'}
                />
            </div>
        }
        <footer className={`footer ${isEmbedded ? 'footer--embedded' : ''}`}>
            <div className="footer__logo">
                <Booked4us />
            </div>
            <a href={'https://' + config.MAIN_PAGE_URL} className="link">
                <FormattedMessage {...messages.getBooked4Us} />
            </a>
        </footer>
    </>
export default Footer;