import React from 'react';
import { useState, useContext } from 'react';
import globalMessages from 'src/services/globalMessages';
import { FormattedMessage } from 'react-intl';
import { Collapse } from 'reactstrap';
import { Routes } from 'src/routes';
import { HashLink as Link } from 'react-router-hash-link';
import { removeToken } from 'src/services/api/authorization';
import ProfileButton from '../ProfileButton';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { hasAboutUs, ICompany } from 'src/entities/company';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from 'src/components/Popup/Popup';
import Login from 'src/components/Login/Login';
import { AppContext } from 'src/contexts/AppContext';
import { useEmbedOptions } from 'src/contexts/EmbedContext';

interface IHeaderMenuProps {
    isOpen: boolean;
    company: ICompany | null;
    hasContactInfo: boolean;
    toggleMenu: () => void;
}

const scroll = (el: HTMLElement) => {
    const id = setInterval(() => {
        if (!document.querySelector('.booking-step--content--active .placeholder')) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            clearInterval(id);
        }
    }, 200);
}

const HeaderMenu = ({ isOpen, company, hasContactInfo, toggleMenu }: IHeaderMenuProps) => {
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const toggleLogin = () => setIsLoginVisible(prev => !prev);

    const { user, actions: { setUser } } = useContext(AppContext);
    const { hideUser, hideCompanyInfo } = useEmbedOptions();

    const navigate = useNavigate();
    const location = useLocation();

    const logOut = () => {
        closeIfOpen();
        setUser(null);
        removeToken();
        navigate({
            pathname: Routes.Book,
            search: location.search
        });
    }

    const closeIfOpen = () => {
        if (isOpen) {
            toggleMenu();
        }
    }

    const JumpLinks = () =>
        <div className="header__links">
            {user ?
                <>
                    <Link className="header__link header__link--mobile" to={Routes.UserDetails} onClick={closeIfOpen}>
                        <FormattedMessage {...globalMessages.userData} />
                    </Link>
                    <Link className="header__link" to={Routes.UserReservations} onClick={closeIfOpen}>
                        <FormattedMessage {...globalMessages.userReservations} />
                    </Link>
                    <Link className="header__link"
                        to={{
                            pathname: Routes.Book,
                            search: location.search
                        }} 
                        onClick={closeIfOpen}>
                        <FormattedMessage {...globalMessages.newReservation} />
                    </Link>
                    <a className="header__link  header__link--mobile" onClick={logOut}>
                        <FormattedMessage {...globalMessages.logOut} />
                    </a>
                </>
                :
                <>
                    {!hideUser && <a className="header__link  header__link--mobile" onClick={toggleLogin}>
                        <FormattedMessage {...globalMessages.login} />
                    </a>}
                    {!hideCompanyInfo && <>
                        {hasContactInfo && <Link scroll={scroll} onClick={closeIfOpen} className="header__link" to="/#contact-info"><FormattedMessage {...globalMessages.contactUs} /></Link>}
                        {hasAboutUs(company) && <Link scroll={scroll} onClick={closeIfOpen} className="header__link" to="/#about-us"><FormattedMessage {...globalMessages.aboutUs} /></Link>}
                    </>}
                </>
            }
        </div>

    return (
        <>
            <Collapse isOpen={isOpen} navbar={true}>
                <div className="header__menu">
                    <JumpLinks />
                    <LanguageSwitcher />
                    {!hideUser && <ProfileButton toggleLogin={toggleLogin} logOut={logOut} />}
                </div>
            </Collapse>
            <Popup closePopup={toggleLogin} visible={isLoginVisible}>
                <Login setUser={setUser} />
            </Popup>
        </>
    );
}

export default HeaderMenu;