import { IValidationRule, emailFormat, required } from './validations';
import { IUserCredentials } from 'src/entities/user';

const getLoginFields = () => {
    const usernameField: keyof IUserCredentials = 'username';
    const passwordField: keyof IUserCredentials = 'password';

    return {
        username: usernameField,
        password: passwordField
    }
}

export const loginFields = getLoginFields();

export const loginRules: IValidationRule[] = [
    required(loginFields.username),
    required(loginFields.password),
    emailFormat(loginFields.username)
];