import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { PagePlaceholder } from '../Placeholder/Placeholder';

interface IRequireAuthProps {
    element: React.ReactElement;
}

const RequireAuth: React.FC<IRequireAuthProps> = ({ element }) => {
    const { isAuthenticated } = useAppContext();
    const { pathname } = useLocation();

    return (
        <>
            {isAuthenticated === undefined ? (
                <PagePlaceholder />
            ) : (
                <>
                    {isAuthenticated ? (
                        element
                    ) : (
                        <Navigate to={'/Account/Login?customerReturnUrl=' + pathname} />
                    )}
                </>
            )}
        </>
    );
};

export default RequireAuth;
