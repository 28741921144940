import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { IUserCredentials } from '../../entities/user';
import { login } from '../../services/api/user';
import Checkbox from '../form-components/Checkbox/Checkbox';
import PrimaryButton from '../form-components/PrimaryButton/PrimaryButton';
import TextInput, { TextInputType, createInputId } from '../form-components/TextInput/TextInput';
import { IPopupable } from '../Popup/Popup';
import { Link, Navigate } from 'react-router-dom';
import { IHandledError } from 'src/services/api/errorHandler';
import ValidationMessage from '../form-components/ValidationMessage/ValidationMessage';
import FormValidator from 'src/services/formValidator';
import { loginFields, loginRules } from 'src/services/validations/loginRules';
import { Routes } from 'src/routes';
import globalMessages from 'src/services/globalMessages';
import { isUserElevated } from 'src/services/api/authorization';
import { useForm } from 'src/hooks';
import { useState, useEffect } from 'react';
import { getQueryParam, useQuery } from 'src/services/queryString';
import { IAppProvidedActions } from 'src/contexts/AppContext';
import config, { DEV_ENV } from '../../constants';

export interface ILoginState extends IHandledError {
    user: IUserCredentials;
    submitted: boolean;
    redirect?: string;
}

export const enum LoginField {
    UserName = 'username',
    Password = 'password',
    KeepLoggedIn = 'keepLoggedIn'
}

const messages = defineMessages({
    signUpLink: {
        id: 'signUpLink',
        defaultMessage: '<a>Regisztrálj</a>, ha nincs felhasználói fiókod!'
    },
    stayLoggedIn: {
        id: 'stayLoggedIn',
        defaultMessage: 'Maradjak bejelentkezve'
    }
});

const validator = new FormValidator(loginRules);

type ILoginProps = Pick<IAppProvidedActions, 'setUser'>;

const Login: React.FC<ILoginProps & IPopupable> = (props) => {

    const { closePopup, setUser } = props;

    const [credentials, setCredentials] = useState<IUserCredentials>({
        username: '',
        password: '',
        keepLoggedIn: false
    });

    const urlParams = useQuery();
    const customerReturnUrl = getQueryParam('customerReturnUrl', urlParams);

    const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

    const { validation, result, errorMessage, handleSubmit } = useForm(
        validator,
        credentials,
        () => login(credentials));

    useEffect(() => {
        if (result) {
            setUser(result);
            closePopup?.();
            redirect();
        }
    }, [result])

    const redirect = () => {
        let mvcReturnUrl = getQueryParam('returnUrl', urlParams);
        mvcReturnUrl = mvcReturnUrl && decodeURIComponent(mvcReturnUrl);
        
        const reactReturnUrl = customerReturnUrl || mvcReturnUrl;

        if (isUserElevated()) {
            window.location.href = (process.env.NODE_ENV === DEV_ENV ? config.MVC_URL : window.location.origin) + (mvcReturnUrl || '/Admin');
        } else if (reactReturnUrl) {
            setRedirectUrl(reactReturnUrl)
        } else if (!closePopup) {
            setRedirectUrl(Routes.HomeSlash);
        }
    }

    const handleInputChange = (value: string | boolean, field: LoginField) => {
        setCredentials(state => ({
            ...state,
            [field]: value
        }
        ));
    }

    const getCustomerReturnUrlQuery = () => {
        return customerReturnUrl ? `?customerReturnUrl=${customerReturnUrl}` : ''
    }

    if (redirectUrl) {
        return <Navigate to={redirectUrl} />;
    }

    return (
        <section className={`login ${closePopup ? 'login--popup' : ''}`}>
            <h1 className="login__title"><FormattedMessage {...globalMessages.login} /></h1>
            <p className="login__signup-message p--light-text">
                <FormattedMessage
                    {...messages.signUpLink}
                    values={{
                        a: (msg: string) =>
                            <Link
                                to={Routes.Register + getCustomerReturnUrlQuery()}
                                className="link"
                                onClick={() => closePopup?.()}>
                                {msg}
                            </Link>
                    }}
                />
            </p>
            <form className="login__fields App__form" onSubmit={e => { e.preventDefault(); handleSubmit(); }} noValidate={true}>
                <TextInput
                    autoFocus={true}
                    value={credentials[LoginField.UserName]}
                    className="login__field"
                    fieldName={LoginField.UserName}
                    label={globalMessages.email}
                    type={TextInputType.Email}
                    onChange={handleInputChange}
                    id={createInputId(LoginField.UserName)}
                />
                <ValidationMessage message={validation.fields[loginFields.username].message} />

                <TextInput
                    className="login__field"
                    value={credentials[LoginField.Password]}
                    fieldName={LoginField.Password}
                    label={globalMessages.password}
                    type={TextInputType.Password}
                    onChange={handleInputChange}
                    id={createInputId(LoginField.Password)}
                />
                <ValidationMessage message={validation.fields[loginFields.password].message} />

                <Checkbox className="login__field" fieldName={LoginField.KeepLoggedIn} onChange={handleInputChange}>
                    <p><FormattedMessage {...messages.stayLoggedIn} /></p>
                </Checkbox>
                <ValidationMessage className="login__error" message={errorMessage} />
                <div>
                    <PrimaryButton
                        onClick={() => undefined}
                        className="login__button user-form__submit"
                        caption={globalMessages.login}
                        type="submit"
                    />
                </div>
            </form>
            <Link
                to={Routes.ForgotPassword}
                className="link login__forgot-password"
                onClick={() => closePopup?.()}
            >
                <FormattedMessage {...globalMessages.forgotPasswordTitle} />
            </Link>
        </section>
    );
}

export default Login;
