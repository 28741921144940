import React from 'react';
import ErrorBox from './ErrorBox/ErrorBox';

interface IErrorBoundaryState {
    hasError: boolean;
    errorMessage: string;
}

class ErrorBoundary extends React.Component<object, IErrorBoundaryState> {
    constructor(props: object) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: ''
        };
    }

    public componentDidCatch(error: any) {
        this.setState({
            hasError: true,
            errorMessage: error.message
        });
    }

    public render() {
        if (this.state.hasError) {
            return <ErrorBox message={this.state.errorMessage} />;
        }
        return (
            <>
                {this.props.children}
            </>
        );
    }
}

export default ErrorBoundary;
