import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IButtonProps } from '../Button/Button';
import { isMessageDescriptor } from '../helpers';

type IPrimaryButtonProps = IButtonProps;

const PrimaryButton: React.FunctionComponent<IPrimaryButtonProps> = ({ caption, dangerous, className, type = 'button', ...rest }) => {
    const attr = {
        className: classNames(
            'button',
            { 'button--primary': !dangerous },
            { 'button--primary-dangerous': dangerous },
            className,
        ),
        type,
        ...rest
    }

    return (
        isMessageDescriptor(caption) ?
            <FormattedMessage {...caption}>
                {(text) => <button {...attr}>{text}</button>}
            </FormattedMessage> :
            <button {...attr}>{caption}</button>
    );
}

export default PrimaryButton;