export const useQuery = () => {
    return new URLSearchParams(window.location.search);
}

export const getQueryParam = (paramName: string, queryString?: URLSearchParams) =>  {
    const urlParams = queryString || useQuery();

    return urlParams.get(paramName) || urlParams.get(capitalize(paramName));
}

function capitalize(s: string)
{
    return s && s[0].toUpperCase() + s.slice(1);
}