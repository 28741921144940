import React from 'react';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import { useContext } from 'react';
import globalMessages from 'src/services/globalMessages';
import { useIntl } from 'react-intl';
import Profile from '../icons/Profile';
import { AppContext } from 'src/contexts/AppContext';

interface IProfileButtonProps {
    isEmbedded?: boolean;
    toggleLogin: () => void;
    logOut: () => void;
}

const ProfileButton = ({ isEmbedded, toggleLogin, logOut }: IProfileButtonProps) => {

    const { user } = useContext(AppContext);
    const intl = useIntl();

    return (
        <div className="header__account">
            {user
                ? <ProfileMenu logOut={logOut} />
                : <>
                    <button
                        className={'header__button header__icon' + (!isEmbedded ? '' : ' App__user-button icon')}
                        onClick={toggleLogin}
                        aria-haspopup="dialog"
                        aria-controls="" // TODO add id attribute to popup, use there
                        aria-label={intl.formatMessage(globalMessages.login)}
                    >
                        <Profile />
                    </button>
                </>
            }
        </div>
    );
}

export default ProfileButton;