import React from 'react';
import { AppLocale } from 'src/entities/modules.js';
import { lazyTranslations } from '../../services/lazyTranslations';
import { IntlProvider } from 'react-intl';
import { postLanguage } from 'src/services/api/company';
import { Helmet } from 'react-helmet';
import { localStore } from 'src/services/storage';
import { IntlContext } from 'src/contexts/IntlContext';
import config from 'src/constants';

export interface IIntlContextState {
    locale: string;
    alreadySwitched: boolean;
    isFormal: boolean;
    messages: any;
    setLocale: (locale: AppLocale, isFormal?: boolean) => void;
}

const { Provider, Consumer } = IntlContext;
const languagesWithFormalOption = ['hu', 'de'];

class IntlProviderWrapper extends React.Component<object, IIntlContextState> {

    public state: IIntlContextState = {
        locale: localStore.getItem('locale') || config.DEFAULT_LOCALE,
        messages: {},
        alreadySwitched: !!localStore.getItem('localeSwitchedByUser') || false,
        isFormal: false,
        setLocale: (locale, isFormal) => this.setLocale(locale, isFormal)
    }

    public async componentDidMount() {
        this.loadMessages();
    }

    public render() {
        const { children } = this.props;
        const { locale, messages } = this.state;

        return (
            <>
                <Helmet>
                    <html lang={locale} />
                </Helmet>
                <Provider value={this.state}>
                    <IntlProvider
                        key={locale}
                        locale={locale}
                        messages={messages}
                        defaultLocale={config.DEFAULT_LOCALE}
                        timeZone={config.SERVER_TIME_ZONE}
                    >
                        {children}
                    </IntlProvider>
                </Provider>
            </>
        );
    }

    private setLocale = (locale: AppLocale, isFormal?: boolean) => {
        this.setState(prev => ({ locale, isFormal: isFormal ?? prev.isFormal, alreadySwitched: true }), this.loadMessages);
    };

    private loadMessages = async () => {
        const { locale, isFormal } = this.state;
        const hasFormalOption = languagesWithFormalOption.includes(locale);

        const messages = await lazyTranslations[locale + (isFormal && hasFormalOption ? '-formal' : '') as keyof typeof lazyTranslations];

        localStore.setItem('locale', locale);
        postLanguage(locale).catch(() => null);

        this.setState({ messages });
    }
}

export { IntlProviderWrapper as IntlProvider, Consumer as IntlConsumer };
