import { ReservationOrder } from 'src/entities/company';
import { IReservationModel } from 'src/entities/reservation';

export const enum StepType {
    Calendar = 'calendar',
    Timetable = 'timetable',
    Service = 'service',
    Date = 'date',
    CustomerData = 'customerData',
    ReservationEnd = 'reservationEnd'
}

export const enum StepOrderType {
    CalendarFirstWithQuestions,
    ServiceFirstWithQuestions,
    TimetableWithQuestions,
    OnlyServiceWithQuestions,
    CalendarFirst,
    ServiceFirst,
    Timetable,
    OnlyService
}

const stepOrderPairs = [
    [StepOrderType.CalendarFirstWithQuestions, StepOrderType.CalendarFirst],
    [StepOrderType.ServiceFirstWithQuestions, StepOrderType.ServiceFirst],
    [StepOrderType.TimetableWithQuestions, StepOrderType.Timetable],
    [StepOrderType.OnlyServiceWithQuestions, StepOrderType.OnlyService]
]

const stepOrderMap = new Map<StepOrderType, StepType[]>([
    [
        StepOrderType.CalendarFirstWithQuestions,
        [StepType.Calendar, StepType.Service, StepType.Date, StepType.CustomerData, StepType.ReservationEnd]
    ],
    [
        StepOrderType.ServiceFirstWithQuestions,
        [StepType.Service, StepType.Calendar, StepType.Date, StepType.CustomerData, StepType.ReservationEnd]
    ],
    [
        StepOrderType.TimetableWithQuestions,
        [StepType.Calendar, StepType.Timetable, StepType.CustomerData, StepType.ReservationEnd]
    ],
    [
        StepOrderType.OnlyServiceWithQuestions,
        [StepType.Service, StepType.Date, StepType.CustomerData, StepType.ReservationEnd]
    ],
    [
        StepOrderType.CalendarFirst,
        [StepType.Calendar, StepType.Service, StepType.Date, StepType.ReservationEnd]
    ],
    [
        StepOrderType.ServiceFirst,
        [StepType.Service, StepType.Calendar, StepType.Date, StepType.ReservationEnd]
    ],
    [
        StepOrderType.Timetable,
        [StepType.Calendar, StepType.Timetable, StepType.ReservationEnd]
    ],
    [
        StepOrderType.OnlyService,
        [StepType.Service, StepType.Date, StepType.ReservationEnd]
    ],
]);

const getStepOrder = (orderType: StepOrderType): StepType[] => stepOrderMap.get(orderType) || [];

export const getMatchingStepOrderType = (type: StepOrderType, withQuestions: boolean) => {
    const pair = stepOrderPairs.find(p => p.includes(type));
    return pair ? pair[withQuestions ? 0 : 1] : StepOrderType.CalendarFirst
}

export const mapReservationOrder = (reservationOrder: ReservationOrder): StepOrderType => {
    switch (reservationOrder)
    {
        case ReservationOrder.ServiceFirst:
        case ReservationOrder.ServiceFirstWithAllCalendarOption:
            return StepOrderType.ServiceFirst;
        case ReservationOrder.ServiceWithoutCalendar:
            return StepOrderType.OnlyService;
        default:
            return StepOrderType.CalendarFirst;
    }
}

const getStepOrderForReservationModel = (reservation: IReservationModel, baseStepOrderType: StepOrderType) : StepOrderType => {
    const hasQuestions = reservation[StepType.CustomerData] !== undefined;

    if (reservation[StepType.Timetable]) {
        return getMatchingStepOrderType(StepOrderType.Timetable, hasQuestions);
    }
    return getMatchingStepOrderType(baseStepOrderType, hasQuestions)
}

export const reloadReservationStepOrders = (reservationOrder: ReservationOrder, reservations: IReservationModel[]) : StepOrderType[] => {
    const baseStepOrderType = mapReservationOrder(reservationOrder);

    return reservations.map(reservation => {
        return getStepOrderForReservationModel(reservation, baseStepOrderType);
    })
}

export default getStepOrder;
