import { IReservationModel } from './reservation';

/**
 * @description Supported locales:
 * 'hu', 'sk', 'en', 'de' and their formal variants
 */
export enum AppLocale {
    Hu = 'hu',
    HuFormal = 'hu-formal',
    Sk = 'sk',
    De = 'de',
    DeFormal = 'de-formal',
    En = 'en'
}

/**
 * @description Base configuration options (props) which are extended by Booked4US Modules.
 * @property companyBaseUrl - Your company booked4.us or termin-direkt.de address.
 *                            Example: `https://{your-company-name}.booked4.us` or `https://{your-company-name}.termin-direkt.de`
 * @property locale         - Module language. Supported locales: 'hu', 'sk', 'en', 'de'. Default is 'hu'.
 */
export interface ICommonModuleProps {
    companyBaseUrl?: string;
    locale?: AppLocale;
}

/**
 * @description Configuration options (props) which are extended by Booked4US Modules.
 * @property data       - Reservation data. Modules could be invoked with default data.
 * @property isActive   - Whether the module is able to autoselect.
 * @property isFirst    - Whether the module is the first step in a booking flow.
 * @property onComplete - Module event. It is triggered when user clicks next button in the module. First argument is the selected value. Second argument is whether the selection was determined without user input.
 */
export interface IStepModuleProps extends ICommonModuleProps {
    data: IReservationModel;
    isActive: boolean;
    isFirst?: boolean;
    onComplete: (value: IReservationModel, hasSkipped?: boolean) => void;
    onIsModifiable?: (value: boolean) => void;
    onLoadingChanged?: (isLoading: boolean) => void;
}

/**
 * Supported location search params for booking steps.
 * If you define these search params in location then the corresponding booking step module will automatically select that value
 */
export enum ModuleSearchParam {
    CalendarId = 'CalendarId',
    ServiceId = 'ServiceId',
    Date = 'Date'
}
