import { defineMessages, MessageDescriptor } from 'react-intl';
import { StepType } from './getStepOrder';

const globalMessages = defineMessages({
    serviceDescriptionStartReservation: {
        id: 'startReservation',
        defaultMessage: 'Foglalok'
    },
    next: {
        id: 'next',
        defaultMessage: 'Tovább'
    },
    readMore: {
        id: 'readMore',
        defaultMessage: 'Bővebben'
    },
    nPersons: {
        id: 'nPersons',
        defaultMessage: '{n} <span>fő</span>'
    },
    [StepType.ReservationEnd]: {
        id: 'reservationEnd',
        defaultMessage: 'Véglegesítés'
    },
    modify: {
        id: 'modify',
        defaultMessage: 'Módosít'
    },
    delete: {
        id: 'delete',
        defaultMessage: 'Töröl'
    },
    firstFreeSlot: {
        id: 'firstFreeSlot',
        defaultMessage: 'Első szabad időpont'
    },
    full: {
        id: 'full',
        defaultMessage: 'megtelt'
    },
    notAvailable: {
        id: 'notAvailable',
        defaultMessage: 'nem foglalható'
    },
    close: {
        id: 'close',
        defaultMessage: 'Bezár'
    },
    contactUs: {
        id: 'contactUs',
        defaultMessage: 'Kapcsolat'
    },
    aboutUs: {
        id: 'aboutUs',
        defaultMessage: 'Rólunk'
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Mégse'
    },
    login: {
        id: 'login',
        defaultMessage: 'Bejelentkezés'
    },
    save: {
        id: 'save',
        defaultMessage: 'Mentés'
    },
    requestNewPassword: {
        id: 'requestNewPassword',
        defaultMessage: 'Jelszó igénylése'
    },
    setNewPassword: {
        id: 'setNewPassword',
        defaultMessage: 'Jelszó beállítása'
    },
    newPassword: {
        id: 'newPassword',
        defaultMessage: 'Új jelszó'
    },
    confirmPassword: {
        id: 'confirmPassword',
        defaultMessage: 'Jelszó megerősítése'
    },
    confirmNewPassword: {
        id: 'confirmNewPassword',
        defaultMessage: 'Új jelszó megerősítése'
    },
    password: {
        id: 'password',
        defaultMessage: 'Jelszó'
    },
    personalDetails: {
        id: 'personalDetails',
        defaultMessage: 'Személyes adatok'
    },
    privacyAccept: {
        id: 'privacyAccept',
        defaultMessage: 'Hozzájárulok személyes adataim kezeléséhez az <span>adatkezelési hozzájárulásban</span> foglaltak szerint.'
    },
    userData: {
        id: 'userData',
        defaultMessage: 'Adataim'
    },
    newReservation: {
        id: 'newReservation',
        defaultMessage: 'Foglalás indítása'
    },
    email: {
        id: 'email',
        defaultMessage: 'E-mail cím'
    },
    phoneNumber: {
        id: 'phoneNumber',
        defaultMessage: 'Telefonszám'
    },
    name: {
        id: 'name',
        defaultMessage: 'Név'
    },
    confirmDelete: {
        id: 'confirmDelete',
        defaultMessage: 'Biztosan törölni szeretnéd a foglalást?'
    },
    yes: {
        id: 'yes',
        defaultMessage: 'Igen'
    },
    changePassword: {
        id: 'changePassword',
        defaultMessage: 'Jelszó megváltoztatása'
    },
    changePasswordSuccess: {
        id: 'changePasswordSuccess',
        defaultMessage: 'A jelszó beállítása sikeres volt.'
    },
    validationRequired: {
        id: 'validationRequired',
        defaultMessage: 'A mező kitöltése kötelező.'
    },
    forgotPasswordTitle: {
        id: 'forgotPasswordTitle',
        defaultMessage: 'Elfelejtetted a jelszavad?'
    },
    validationEmailFormat: {
        id: 'validationEmailFormat',
        defaultMessage: 'Az e-mail cím formátuma nem megfelelő.'
    },
    validationPhoneFormat: {
        id: 'validationPhoneFormat',
        defaultMessage: 'A telefonszám formátuma nem megfelelő.'
    },
    firstStepAction: {
        id: 'firstStepAction',
        defaultMessage: 'Foglalok'
    },
    dateTimeRequired: {
        id: 'dateTimeRequired',
        defaultMessage: 'Válassz időpontot a továbblépéshez!'
    },
    customerData: {
        id: 'customerData',
        defaultMessage: 'Adatok'
    },
    userReservations: {
        id: 'userReservations',
        defaultMessage: 'Foglalásaim'
    },
    dateTime: {
        id: 'dateTime',
        defaultMessage: 'Időpont'
    },
    whichever: {
        id: 'whichever',
        defaultMessage: 'Mindegy'
    },
    ok: {
        id: 'ok',
        defaultMessage: 'Ok'
    },
    minute: {
        id: 'minute',
        defaultMessage: '{n} perc'
    },
    signupSuccessText:
    {
        id: 'signupSuccessText',
        defaultMessage: 'Sikeres regisztráció...'
    },
    logOut: {
        id: 'logOut',
        defaultMessage: 'Kijelentkezés'
    },
    dataPolicyTitle: {
        id: 'dataPolicyTitle',
        defaultMessage: 'Adatkezelési hozzájárulás'
    },
    dataPolicyOk: {
        id: 'dataPolicyOk',
        defaultMessage: 'Rendben'
    },
    invoiceDetails: {
        id: 'invoiceDetails',
        defaultMessage: 'Számlázási adatok'
    },
    toBePaid: {
        id: 'toBePaid',
        defaultMessage: 'Online fizetendő: '
    },
    paidTransactionWarning: {
        id: 'paidTransactionWarning',
        defaultMessage: 'A foglaláshoz kifizetett tranzakció tartozik!'
    },
    subtotal: {
        id: 'subtotal',
        defaultMessage: 'Végösszeg: '
    },
    nAnswers: {
        id: 'nAnswers',
        defaultMessage: '{n} válasz'
    },
    transactionId: {
        id: 'transactionId',
        defaultMessage: 'Tranzakció azonosító: '
    }
});

type IAppViewType = 'viewAdmin' | 'viewClient';

export const viewTypeMessages = defineMessages<IAppViewType, MessageDescriptor>({
    viewAdmin: {
        id: 'viewAdmin',
        defaultMessage: 'Admin oldal'
    },
    viewClient: {
        id: 'viewClient',
        defaultMessage: 'Ügyfél oldal'
    }
})

export default globalMessages;