import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

interface IValidationMessageProps {
    message?: MessageDescriptor;
    formatValues?: { [key: string]: any };
    className?: string
}

const ValidationMessage: React.FunctionComponent<IValidationMessageProps> = ({ message, formatValues, className }) => (
    message ?
        <div className={`validation-message ${className || ''}`}>
            <FormattedMessage {...message} values={formatValues} />
        </div>
        : <></>
);

export default ValidationMessage;
