import React from 'react';

interface IErrorBoxProps {
    message: string;
}

const ErrorBox = (props: IErrorBoxProps) => {
    return (
        <div className="error-box">
            <h1>Oops something went wrong!</h1>
            <details className="error-box__details">
                <ul>
                    <li>
                        <h3>{props.message}</h3>
                    </li>
                </ul>
            </details>
        </div>
    );
}

export default ErrorBox;
