import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { isUserElevated } from 'src/services/api/authorization';
import { Routes } from 'src/routes';
import globalMessages, { viewTypeMessages } from 'src/services/globalMessages';
import { adminLocation } from '../Header';
import Menu, { IMenuButtonProps } from '../Menu';
import Profile from 'src/components/icons/Profile';

interface IProfileMenuProps {
    logOut: () => void;
}

const messages = defineMessages({
    userLinks: {
        id: 'userLinks',
        defaultMessage: 'Linkek'
    }
});

const ProfileMenu: React.FunctionComponent<IProfileMenuProps> = ({ logOut }) => {
    const intl = useIntl();
    const location = useLocation();

    const MenuButton = React.forwardRef<HTMLButtonElement, IMenuButtonProps>((props, ref) =>
        <button {...props} ref={ref} aria-label={intl.formatMessage(messages.userLinks)}>
            <Profile />
        </button>
    )

    return (
        <Menu button={MenuButton}>
            <Link className="profile-menu__link" to={Routes.UserDetails}>
                <FormattedMessage {...globalMessages.userData} />
            </Link>
            <Link className="profile-menu__link" to={Routes.UserReservations}>
                <FormattedMessage {...globalMessages.userReservations} />
            </Link>
            <Link className="profile-menu__link"
                to={{
                    pathname: Routes.Book,
                    search: location.search
                }} >
                <FormattedMessage {...globalMessages.newReservation} />
            </Link>
            {isUserElevated() &&
                <a className="profile-menu__link" href={adminLocation} >
                    <FormattedMessage {...viewTypeMessages.viewAdmin} />
                </a>
            }
            <button className="header__button profile-menu__item--bordered" onClick={logOut}>
                <FormattedMessage {...globalMessages.logOut} />
            </button>
        </Menu>
    );

};

export default ProfileMenu;