import { IResponseDto, IDTO } from './../../entities/common';

const baseResponse: IResponseDto = {
    Success: true,
    Error: null,
    ValidationErrors: []
};

export const createResponse = <T>(value: T): IDTO<T> => ({
    ...baseResponse,
    Data: value
})

export default baseResponse;
