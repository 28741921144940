import React from 'react';
import { AppLocale } from 'src/entities/modules';
import { useContext } from 'react';
import Menu, { IMenuButtonProps } from '../Menu';
import Globe from 'src/components/icons/Globe';
import { defineMessages, useIntl } from 'react-intl';
import Select from 'src/components/form-components/Select/Select';
import { useMediaQuery } from 'react-responsive';
import { localStore } from 'src/services/storage';
import { IntlContext } from 'src/contexts/IntlContext';

const messages = defineMessages({
    selectLanguage: {
        id: 'selectLanguage',
        defaultMessage: 'Nyelv kiválasztása'
    }
});

const languageOptions = Object.entries({
    [AppLocale.En]: 'English',
    [AppLocale.De]: 'Deutsch',
    [AppLocale.Hu]: 'Magyar',
    [AppLocale.Sk]: 'Slovenčina'
});

const LanguageSwitcher = () => {
    const { setLocale } = useContext(IntlContext);
    const intl = useIntl();

    const MenuButton = React.forwardRef<HTMLButtonElement, IMenuButtonProps>((props, ref) =>
        <button {...props} ref={ref} aria-label={intl.formatMessage(messages.selectLanguage)} >
            <Globe />
        </button>
    )

    const setLocaleByUser = (tag: string) => {
        localStore.setItem('localeSwitchedByUser', 'true');
        setLocale(tag);
    }

    const isMobile = useMediaQuery({ maxWidth: '48rem' })

    if (isMobile) {
        return (<Select className="select--with-icon"
            values={
                languageOptions.map(([tag, name]) => (
                    {
                        value: tag,
                        label: name
                    }
                ))}
            value={intl.locale}
            icon={<Globe />}
            onChange={setLocaleByUser} />
        )
    }

    return (
        <Menu button={MenuButton}>
            {languageOptions.map(([tag, name]) =>
                <button
                    key={tag}
                    className="header__button"
                    onClick={() => setLocaleByUser(tag)}
                >
                    {name}
                </button>)
            }
        </Menu>
    )
}

export default LanguageSwitcher;