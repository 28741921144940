import { MessageDescriptor } from 'react-intl';
import { useState, useEffect, RefObject, useCallback, useContext } from 'react';
import makeCancelable, { ICancelablePromise } from './services/cancelablePromise';
import { IHandledError, ICanceledPromiseError, isICanceledPromise } from './services/api/errorHandler';
import FormValidator from './services/formValidator';
import cssVars from 'css-vars-ponyfill';
import { AppContext } from './contexts/AppContext';

export const useSubscription: {
    <T>(promise: () => Promise<T>, initialValue: T): [T, boolean, IHandledError | null];
    <T>(promise: () => Promise<T>): [T | null, boolean, IHandledError | null];
}
    = <T>(promise: () => Promise<T>, initialValue?: any): [any, boolean, IHandledError | null] => {

        const [data, setData] = useState(initialValue === undefined ? null : initialValue);
        const [isLoading, setIsLoading] = useState(true);
        const [error, setError] = useState<IHandledError | null>(null);

        useEffect(() => {
            setIsLoading(true);
            setError(null);
            setData(initialValue === undefined ? null : initialValue);
            const subscription = makeCancelable(promise());
            subscription.promise
                .then(result => {
                    setData(result);
                    setIsLoading(false);
                })
                .catch((err: IHandledError | ICanceledPromiseError) => {
                    if (!isICanceledPromise(err)) {
                        setError(err)
                        setIsLoading(false)
                    }
                });
            return subscription.cancel
        }, [promise])

        return [data, isLoading, error]
    }

export const useOutsideClick = <T extends HTMLElement>(ref: RefObject<T>, callback: () => void) => {
    const handleClick = (e: MouseEvent) => {
        if (!(e.target instanceof Element) || ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => (
        document.addEventListener('click', handleClick),
        () => document.removeEventListener('click', handleClick)
    ), [ref.current]);
};

export function useWindowWidth() {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {

        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return width;
}

export const useKeyboardFocusIndicator = () => {

    const [isKeyboard, setIsKeyboard] = useState(false);

    useEffect(() => {
        if (isKeyboard) {
            if (process.env.NODE_ENV !== 'test') {
                cssVars({
                    variables: { 'outline': '.3rem dotted var(--primary-select-color)' }
                });
            }
        }
        return () => {
            if (process.env.NODE_ENV !== 'test') {
                cssVars({ variables: { 'outline': '' } });
            }
        };
    }, [isKeyboard])

    useEffect(() => {

        const handleKeyUp = (e: KeyboardEvent) => {
            if (e.key === 'Tab' && !isKeyboard) {
                setIsKeyboard(true);
            }
        };
        const handleClick = () => {
            if (isKeyboard) { setIsKeyboard(false); }
        };

        document.addEventListener('keyup', handleKeyUp);
        document.addEventListener('mousedown', handleClick);

        return () => (
            document.removeEventListener('keyup', handleKeyUp),
            document.addEventListener('mousedown', handleClick))
    }, [isKeyboard]);
}

export const useStyleLinks = <T extends HTMLElement>(root: RefObject<T>) => {
    useEffect(() => {
        root.current?.querySelectorAll('a').forEach(a => a.classList.add('link'));
    }, [root.current?.innerHTML])
}

export const useForm = <T>(validator: FormValidator, formState: any, post: () => Promise<T>)
    : {
        validation: any,
        result: T | null,
        errorMessage: MessageDescriptor | undefined,
        handleSubmit: () => void;
    } => {

    const { actions: { setLoading } } = useContext(AppContext);

    const [key, setKey] = useState(0);

    const request = useCallback(
        !key || !validator.validate(formState).isValid
            ? () => Promise.resolve(null)
            : post
        , [key]);

    const [result, isSubmitting, error] = useSubscription(request);
    const [errorMessage, setErrorMessage] = useState(error?.errorMessage);

    useEffect(() => {
        setLoading(isSubmitting);
        return () => setLoading(false);
    }, [isSubmitting]);

    useEffect(() => {
        setErrorMessage(undefined);
    }, [JSON.stringify(formState)]);

    useEffect(() => {
        setErrorMessage(error?.errorMessage);
    }, [error])

    const validation = key ?
        validator.validate(formState) :
        validator.getInitial();

    const handleSubmit = () => setKey(i => isSubmitting ? i : i + 1);

    return { validation, result, errorMessage, handleSubmit };
}

export const useConditionalFetch: {
    <T>(
        promise: () => Promise<T>,
        callback: (result: T) => void,
        condition: boolean,
        initialValue: T
    ): [T, boolean, IHandledError | null];
    <T>(
        promise: () => Promise<T>,
        callback: (result: T) => void,
        condition: boolean
    ): [T | null, boolean, IHandledError | null];
}
    = <T>(promise: () => Promise<T>, callback: (result: T) => void, condition: boolean, initialValue?: any): [any, boolean, IHandledError | null] => {

        const [data, setData] = useState(initialValue === undefined ? null : initialValue);
        const [isLoading, setIsLoading] = useState(false);
        const [error, setError] = useState<IHandledError | null>(null);

        useEffect(() => {
            let subscription: ICancelablePromise<T> | null = null;
            if (condition) {
                setIsLoading(true);
                setError(null);
                setData(initialValue === undefined ? null : initialValue);
                subscription = makeCancelable(promise());
                subscription.promise
                    .then(result => {
                        setData(result);
                        setIsLoading(false);
                        callback(result);
                    })
                    .catch((err: IHandledError | ICanceledPromiseError) => {
                        if (!isICanceledPromise(err)) {
                            setError(err)
                            setIsLoading(false)
                        }
                    });
            }
            return subscription?.cancel
        }, [promise, condition])

        return [data, isLoading, error]
    }
