import React from 'react';
import ReactDOM from 'react-dom';
import CircleClose from '../icons/CircleClose';
import { MessageDescriptor, FormattedMessage } from 'react-intl';
import { isMessageDescriptor } from '../form-components/helpers';
import { AnimatePresence, motion } from 'framer-motion';

export interface IPopupable {
    closePopup?: () => void;
}

interface IPopupProps {
    closePopup: () => void;
    visible: boolean;
    title?: string | MessageDescriptor;
    titleClassName?: string;
}

class Popup extends React.Component<IPopupProps> {
    private popupRef = React.createRef<HTMLDivElement>();

    public componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsidePopup);
    }

    public componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsidePopup);
    }

    public render() {
        const { title, titleClassName } = this.props;

        return ReactDOM.createPortal(
            <AnimatePresence>
                {this.props.visible ? (
                      <div className="popup"  >
                          <motion.div
                              className="popup-background"
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 0.6 }}
                              transition={{ type: 'spring', stiffness: 170, damping: 26 }}
                              exit={{ opacity: 0, transition: { type: 'spring', stiffness: 200, damping: 20 } }} />
                          <motion.div
                              className="popup-body"
                              role="dialog"
                              ref={this.popupRef}
                              initial={{ opacity: 0, transform: `translate(-50%, -50%) scale(0.8)` }}
                              animate={{ opacity: 1, transform: `translate(-50%, -50%) scale(1)` }}
                              transition={{ type: 'spring', stiffness: 170, damping:26 }}
                              exit={{ opacity: 0, transform: `translate(-50%, -50%) scale(0.8)`, transition: { type: 'spring', stiffness: 300, damping: 30 } }}
                              >
                              <div className="popup-body__title-wrapper">
                                  {title &&
                                      <h1 className={`popup-body__title ${titleClassName ? titleClassName : ''}`} >
                                          {isMessageDescriptor(title)
                                              ? <FormattedMessage {...title} />
                                              : title
                                          }
                                      </h1>
                                  }
                                  <button className="popup-body__close-icon icon" onClick={this.props.closePopup}>
                                      <CircleClose />
                                  </button>
                              </div>
                              {React.Children.map(this.props.children, child =>
                                  React.isValidElement<IPopupable>(child) ?
                                      React.cloneElement<IPopupable>(child, { closePopup: () => this.props.closePopup() }) : child
                              )}
                          </motion.div>
                      </div>
                ) : null}
            </AnimatePresence>, document.querySelector('.App__container') || document.body);
    }

    private handleClickOutsidePopup = (event: Event & { target: HTMLElement; }) => {
        event.stopPropagation();

        if (this.popupRef.current && !this.popupRef.current.contains(event.target)) {
            this.props.closePopup();
        }
    }
}

export default Popup;
