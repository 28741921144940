import React, { ForwardRefExoticComponent, ReactElement, RefAttributes, useRef, useState } from 'react';
import { useOutsideClick } from 'src/hooks';

interface IMenuProps {
    button: ForwardRefExoticComponent<IMenuButtonProps & RefAttributes<HTMLButtonElement>>;
}

export interface IMenuButtonProps {
    'aria-haspopup': 'menu',
    'aria-expanded': boolean
    'aria-controls': string,
    onClick: () => void
}

const Menu: React.FC<IMenuProps> = ({ children, button: MenuButton }) => {
    const [isMenuVisible, setMenuVisible] = useState(false);

    const toggle = () => setMenuVisible(s => !s);
    const close = () => setMenuVisible(false);

    const ref = useRef<HTMLButtonElement>(null);
    useOutsideClick(ref, close);

    const id = String(Date.now());

    return (
        <div className="menu">
            <MenuButton  {...{
                'aria-haspopup': 'menu',
                'aria-expanded': isMenuVisible,
                'aria-controls': id,
                onClick: toggle,
                ref,
                className: 'header__icon header__button'
            }}
            />
            {isMenuVisible &&
                <ul role="menu" className="menu__menu" id={id}>
                    {React.Children.map(children, child =>
                        <li role="none">
                            {React.isValidElement(child) && React.cloneElement(child as ReactElement<HTMLLIElement>, { role: 'listitem', className: child.props.className + ' menu__item' })}
                        </li>)}
                </ul>
            }
        </div>
    );
}

export default Menu;