import { AppLocale } from 'src/entities/modules';

export const lazyTranslations = {
    get [AppLocale.Hu]() {
        return import(/* webpackChunkName: "transHu" */'../translations/hu.json');
    },
    get [AppLocale.HuFormal]() {
        return import(/* webpackChunkName: "transHuForm" */'../translations/hu-formal.json');
    },
    get [AppLocale.De]() {
        return import(/* webpackChunkName: "transDe" */'../translations/de.json');
    },
    get [AppLocale.DeFormal]() {
        return import(/* webpackChunkName: "transDeForm" */'../translations/de-formal.json');
    },
    get [AppLocale.En]() {
        return import(/* webpackChunkName: "transEn" */'../translations/en.json');
    },
    get [AppLocale.Sk]() {
        return import(/* webpackChunkName: "tranSk" */'../translations/sk.json');
    },
};