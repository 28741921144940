export const enum QuestionType {
    List,
    FreeText,
    YesNo,
    Date
}

export enum QuestionFormatType {
    Any,
    Alpha,
    Numeric
}

export interface IQuestionListValue {
    Text: string;
    Value: string;
}

interface IQuestionMaster {
    Id: number;
    FieldName: string;
    Name: string;
}

export type IQuestion =
    IQuestionMaster &
    {
        Format: QuestionFormatType;
        Required: boolean;
        Type: QuestionType;
        IsHidden?: boolean;
        DefaultValue?: string;
    } &
    ({
        Type: QuestionType.YesNo
    } |
    {
        Type: QuestionType.FreeText
        MinimumValue?: number;
        MaximumValue?: number;
    } |
    {
        Type: QuestionType.Date
        MinimumValue: number;
        MaximumValue: number;
    } |
    {
        Type: QuestionType.List;
        Values: IQuestionListValue[];
    })

export type IJsApiQuestion = IQuestion & {
    Value?: string;
}
    
export interface IAnswer extends IQuestionMaster {
    Value: string;
}