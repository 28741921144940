import React from 'react';
import { useLocation } from 'react-router';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { getTrackingCodes } from 'src/services/api/company';
import { useSubscription } from 'src/hooks';
import config from 'src/constants';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { BarionPixel } from './BarionPixel/BarionPixel';
import { ICookieConsentProps } from '../CookieConsentFooter/CookieConsentFooter';

interface ITrackingProps extends ICookieConsentProps  {
    hasConsent: boolean;
}

export const Tracking: React.FunctionComponent<ITrackingProps> = ({ barionPixelIds, hasConsent }) => {

    const location = useLocation();
    const [isGaInitialized, setisGaInitialized] = useState(false);
    const [trackingCodes] = useSubscription(getTrackingCodes);
    let windowEvent: MessageEvent | null = null;

    useEffect(() => {
        if (!hasConsent) {
            return;
        }

        if (config.GA_TRACKING_ID) {
            ReactGA.initialize([{ trackingId: config.GA_TRACKING_ID }]);
            ReactGA.send({ hitType: 'pageview', page: createGAPath() });
        }
    }, [hasConsent])

    useEffect(() => {
        if (!hasConsent) {
            return;
        }

        if (trackingCodes) {
            if (trackingCodes.FacebookPixel) {
                ReactPixel.init(trackingCodes.FacebookPixel);
                ReactPixel.pageView();
            }

            if (trackingCodes.GTMTrackingId) {
                TagManager.initialize({ gtmId: trackingCodes.GTMTrackingId });
            }

            if (trackingCodes.GATrackingId) {
                const timeoutValue = trackingCodes.GASourceDomain && !windowEvent ? 3000 : 0;
                setTimeout(() => {
                    createCustomTracker();
                }, timeoutValue);
            }
            else if (config.GA_TRACKING_ID) {
                setisGaInitialized(true);
            }
        }
    }, [trackingCodes, hasConsent])

    useEffect(() => {
        if (isGaInitialized) {
            ReactGA.send({ hitType: 'pageview', page: createGAPath() });
        }
    }, [location.pathname, location.search, location.hash])

    function createGAPath() {
        return location.pathname + location.hash + location.search;
    }

    function createCustomTracker() {
        setisGaInitialized(true);
        if (!trackingCodes || !trackingCodes.GATrackingId) {
            return;
        }

        if (windowEvent && trackingCodes.GASourceDomain) {
            if (windowEvent.origin !== trackingCodes.GASourceDomain) {
                return;
            }
        }

        const clientId = windowEvent && windowEvent.data;

        const customTracker =
        {
            gaOptions: { allowLinker: true, clientId, name: 'customTracker' }
        };
        ReactGA.initialize(trackingCodes.GATrackingId, customTracker);
        ReactGA.send({ hitType: 'pageview', page: createGAPath(), send_to: trackingCodes.GATrackingId });
    }

    function createDomainVerificationTag() {
        return trackingCodes?.FacebookDomainVerificationId
            ? <Helmet><meta name='facebook-domain-verification' content={trackingCodes.FacebookDomainVerificationId} /></Helmet>
            : null
    }

    function createBarionPixelTags() {
        return !!barionPixelIds?.length
            ? barionPixelIds.map((barionPixelId, key) => createBarionPixelTag(barionPixelId, key))
            : null
    }

    function createBarionPixelTag(barionPixelId: string, key: number) {
        return <BarionPixel key={key} barionPixelId={barionPixelId} />
    }

    window.addEventListener('message', (event) => {
        windowEvent = event;
    });

    return <>
        {createDomainVerificationTag()}
        {createBarionPixelTags()}
    </>;
}