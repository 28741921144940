import React, { useContext } from 'react';
import JsApiContext, { IJsApiContext } from 'src/contexts/JsApi/JsApiContext';

const JsApi: React.FC = () => {
    const {get, removeEmptyValueFromLists, setQuestionToHidden, setDefaultValue, setRequired} = useContext<IJsApiContext>(JsApiContext);

    (window as any).reactJsApi = {
        get,
        removeEmptyValueFromLists,
        setQuestionToHidden,
        setDefaultValue,
        setRequired
    };

    return (<></>);
};

export default JsApi;
