import React, { useState } from 'react';
import JsApiContext from './JsApiContext';
import { IJsApiQuestion, IQuestion, QuestionType } from '../../entities/question';

interface IJsApiContextProvider {
    children: React.ReactNode;
}

const JsApiContextProvider: React.FC<IJsApiContextProvider> = ({ children }) => {
    const [data, setData] = useState<IJsApiQuestion[]>([]);
    
    const get = () : IJsApiQuestion[] => {
        return data;
    };

    const isQuestionExist = (questionId: number) : boolean => {
        const question = data.find(q => q.Id === questionId);
        
        if (question) {
            return true;
        } 

        return false;
    }

    const setQuestionValue = (questionId: number, value: string) => {
        if(!isQuestionExist(questionId)){
            return;
        }

        updateQuestions(questionId, q => ({ ...q, Value: value ?? null }));
    };

    const cleanQuestionValue = (questionId: number) => {
        if(!isQuestionExist(questionId)){
            return;
        }

        updateQuestions(questionId, q => ({ ...q, Value: null }));
    }

    const updateQuestions = (questionId: number, updateFn: (question: IJsApiQuestion) => IQuestion) => {
        setData(prevData =>
            prevData.map(question =>
                question.Id !== questionId ? question : updateFn(question)
            )
        );
    };
    
    const removeEmptyValueFromLists = () => {
        setData(prevData => 
            prevData.map(question => 
                question.Type === QuestionType.List 
                    ? { ...question, Values: question.Values.filter(value => value.Value !== ''), DefaultValue: question.Values[0]?.Value ?? '' } 
                    : question
            )
        );
    };
    
    const setQuestionToHidden = (questionId: number, isHidden: boolean) => {
        if(!isQuestionExist(questionId)){
            // eslint-disable-next-line no-console
            return console.error('Question not found');
        }
        
        updateQuestions(questionId, q => ({ ...q, IsHidden: isHidden, Required: isHidden ? false : q.Required }));
    };

    const setDefaultValue = (questionId: number, value: string) => {
        const question = data.find(q => q.Id === questionId);

        if (!question) {
            // eslint-disable-next-line no-console
            return console.error('Question not found');
        }

        if (question.Type !== QuestionType.List) {
            // eslint-disable-next-line no-console
            return console.error('You can only set default value for List types');
        }

        updateQuestions(questionId, q => ({ ...q, DefaultValue: value }));
    };
    
    const setRequired = (questionId: number, isRequired: boolean) => {
        if(!isQuestionExist(questionId)){
            // eslint-disable-next-line no-console
            return console.error('Question not found');
        }

        updateQuestions(questionId, q => ({ ...q, Required: isRequired, IsHidden: isRequired ? false : q.IsHidden }));
    };

    return (
        <JsApiContext.Provider value={{ data, get, setData, removeEmptyValueFromLists, setQuestionToHidden, setDefaultValue, setRequired, setQuestionValue, cleanQuestionValue }}>
            {children}
        </JsApiContext.Provider>
    );
};

export default JsApiContextProvider;