import React from 'react';
import Circle from './Circle';

const Profile = () =>
    <Circle size="small">
        <svg width="17px" height="21px" x="7" y="5" viewBox="-3.63064 -66.0327 1030.76 962.035">
            <path fill="currentColor" d="M511.728 896c108.672 0 223.92 -91.5342 223.92 -159.854v-159.92c0 -61.5518 -25.5996 -179.312 -94.2559 -233.376c-17.5039 -13.7764 -26.5918 -35.6807 -23.9678 -57.8086c2.62402 -22.1602 16.5918 -41.3125 36.8477 -50.625l278.496 -132.064 c2.17578 -0.992188 26.6875 -5.10449 26.6875 -39.3447l0.0322266 -62.4639l-895.488 -0.0478516v64.9443c0 25.4404 19.0879 33.4248 26.7197 36.9443l281.024 132.624c20.1602 9.24805 34.0645 28.3203 36.7686 50.3203c2.71973 22 -6.16016 43.8398 -23.4561 57.7119 c-66.4805 53.376 -97.4561 170.704 -97.4561 233.185v159.92c0.015625 66.8477 116.416 159.855 224.128 159.854zM511.728 960.002c-141.312 0 -288.127 -117.938 -288.127 -223.857v-159.92c0 -69.8721 31.8877 -211.248 121.392 -283.088l-281.04 -132.64 s-63.9521 -28.4961 -63.9521 -63.9678v-96.0322c0 -35.3438 28.6396 -63.9678 63.9512 -63.9678h895.552c35.3438 0 63.9678 28.624 63.9678 63.9678v96.0322c0 37.5996 -63.9678 63.9678 -63.9678 63.9678l-278.496 132.064 c88.6562 69.7764 118.656 206.849 118.656 283.665v159.92c0 105.92 -146.64 223.855 -287.936 223.855v0z" />
        </svg>
    </Circle>

export default Profile;