import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import classNames from 'classnames';
import { isMessageDescriptor } from '../helpers';
import { useState } from 'react';

export const enum TextInputType {
    Text = 'text',
    Number = 'number',
    Email = 'email',
    Password = 'password',
    Phone = 'tel'
}

export const createInputId = (name: string) => name + Date.now();

interface ITextInputProps {
    fieldName: string;
    onChange?: (value: string, field: string) => void;
    value?: string;
    label?: MessageDescriptor | string;
    labelClassName?: string;
    type?: TextInputType;
    required?: boolean;
    className?: string;
    isMultiLine?: boolean;
    autoFocus?: boolean;
    id?: string;
    disabled?: boolean;
}

const TextInput: React.FC<ITextInputProps> = ({ fieldName, onChange, value, label, labelClassName, type, required, className, isMultiLine, autoFocus, id, disabled }) => {

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.value, fieldName);
    }

    const format = (placeholder?: string) => {
        if (!placeholder) {
            return;
        }
        return placeholder + (required ? ' *' : '')
    }

    const [isFocused, setIsFocused] = useState(false);

    let attrs: any = {
        name: fieldName,
        value,
        onChange: handleInputChange,
        className: classNames(
            'text-input__input',
            { 'text-input__input--multi-line': isMultiLine && value && value.length > 26 }
        ),
        autoFocus,
        id
    };

    if (!isMultiLine) {
        attrs = { ...attrs, type: type || TextInputType.Text }
    }
    else {
        attrs = { ...attrs, rows: 1 }
    }

    if (disabled) {
        attrs = { ...attrs, disabled }
    }

    const element = () => {
        const Element = !isMultiLine ? 'input' : 'textarea';
        return <Element {...attrs}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
        />
    }

    const intl = useIntl();

    return (
        <div className={classNames('text-input', className)}>
            {label &&
                <label
                    className={classNames(
                        'text-input__label',
                        { 'text-input__label--shrunk': value || isFocused },
                        labelClassName
                    )}
                    htmlFor={id}
                >
                    {isMessageDescriptor(label)
                        ? format(intl.formatMessage(label))
                        : format(label)
                    }
                </label>
            }
            {element()}
        </div>
    )
}

export default TextInput;