import { defineMessages, MessageDescriptor } from 'react-intl';
import { IResponseDto, ILoginErrorDto } from 'src/entities/common';
import globalMessages from '../globalMessages';

export const enum ErrorCode {
    RequiredField = 1,
    InvalidFormat = 20,
    InvalidEmailFormat = 21,
    InvalidPhoneFormat = 22,
    InvalidField = 3,
    LocationNotExist = 41,
    ServiceNotExist = 42,
    ReservationNotExist = 43,
    ScheduleNotExist = 44,
    UserNotExist = 45,
    ImageNotExist = 46,
    Error = 5,
    NotScheduleTypeCalendar = 61,
    ReservationAlreadyAccepted = 71,
    UserGeneralError = 80,
    UserEmailAlreadyExist = 81,
    UserEmailInMultipleInstance = 82,
    UserEmailChangeFailed = 83,
    WrongPasswordToken = 84,
    WrongPassword = 85,
    WrongPasswordLength = 86
}

export const enum ModifyReservationErrorCode {
    AlreadyChanged = 5,
    ReservationCollision = 7
}

export const errorMessages = defineMessages({
    errorInvalidFormat: {
        id: 'errorInvalidFormat',
        defaultMessage: 'A megadott érték formátuma nem megfelelő.'
    },
    errorInvalidField: {
        id: 'errorInvalidField',
        defaultMessage: 'A megadott érték érvénytelen.'
    },
    errorLocationNotExist: {
        id: 'errorLocationNotExist',
        defaultMessage: 'A megadott naptár nem létezik.'
    },
    errorServiceNotExist: {
        id: 'errorServiceNotExist',
        defaultMessage: 'A megadott szolgáltatás nem létezik.'
    },
    errorReservationNotExist: {
        id: 'errorReservationNotExist',
        defaultMessage: 'A megadott foglalás nem létezik.'
    },
    errorScheduleNotExist: {
        id: 'errorScheduleNotExist',
        defaultMessage: 'A megadott órarendi időpont nem létezik.'
    },
    errorUserNotExist: {
        id: 'errorUserNotExist',
        defaultMessage: 'A felhasználó nem létezik.'
    },
    errorImageNotExist: {
        id: 'errorImageNotExist',
        defaultMessage: 'Ez a kép nem létezik.'
    },
    errorUndefinedError: {
        id: 'errorUndefinedError',
        defaultMessage: 'Általános hiba.'
    },
    errorNotScheduleTypeCalendar: {
        id: 'errorNotScheduleTypeCalendar',
        defaultMessage: 'A naptár nem órarendes típusú.'
    },
    errorReservationAlreadyAccepted: {
        id: 'errorReservationAlreadyAccepted',
        defaultMessage: 'Ezt a foglalást már megerősítették.'
    },
    errorUserEmailAlreadyExist: {
        id: 'errorUserEmailAlreadyExist',
        defaultMessage: 'A megadott e-mail címmel már regisztráltak.'
    },
    errorUserEmailInMultipleInstance: {
        id: 'errorUserEmailInMultipleInstance',
        defaultMessage: 'Az e-mail cím több időpontfoglalóban is szerepel.'
    },
    errorUserEmailChangeFailed: {
        id: 'errorUserEmailChangeFailed',
        defaultMessage: 'Az e-mail cím változtatás sikertelen volt.'
    },
    errorWrongPasswordToken: {
        id: 'errorWrongPasswordToken',
        defaultMessage: 'A jelszó beállítása sikertelen volt. A jelszó beállító link lejárt.'
    },
    errorWrongPassword: {
        id: 'errorWrongPassword',
        defaultMessage: 'Hibás jelszó.'
    },
    errorWrongPasswordLength: {
        id: 'errorWrongPasswordLength',
        defaultMessage: 'A jelszó hossza nem megfelelő.'
    },
    generalValidationError: {
        id: 'generalValidationError',
        defaultMessage: 'Hiba a megadott adatokban.'
    },
    loginError: {
        id: 'loginError',
        defaultMessage: 'A megadott e-mail cím vagy jelszó helytelen.'
    },
    reservationCollision: {
        id: 'reservationCollision',
        defaultMessage: 'Időközben más is foglalt az általad választott időpontra, kérjük próbáld újra!'
    },
    reservationAlreadyChanged: {
        id: 'reservationAlreadyChanged',
        defaultMessage: 'Sikertelen módosítás! Időközben valaki más már megváltoztatta a foglalást. Lépj vissza és próbáld újra!'
    }
})

export interface ICanceledPromiseError {
    isCanceled?: boolean;
}

export interface IHandledError {
    errorMessage?: MessageDescriptor;
}

export function isICanceledPromise(error: IHandledError | ICanceledPromiseError): error is ICanceledPromiseError {
    return (error as ICanceledPromiseError).isCanceled !== undefined;
}

export const creatErrorMessage = (errorCode: number): MessageDescriptor => {
    switch (errorCode) {
        case ErrorCode.RequiredField:
            return globalMessages.validationRequired;
        case ErrorCode.InvalidFormat:
            return errorMessages.errorInvalidFormat;
        case ErrorCode.InvalidEmailFormat:
            return globalMessages.validationEmailFormat;
        case ErrorCode.InvalidPhoneFormat:
            return globalMessages.validationPhoneFormat;
        case ErrorCode.InvalidField:
            return errorMessages.errorInvalidField;
        case ErrorCode.LocationNotExist:
            return errorMessages.errorLocationNotExist;
        case ErrorCode.ServiceNotExist:
            return errorMessages.errorServiceNotExist;
        case ErrorCode.ReservationNotExist:
            return errorMessages.errorReservationNotExist;
        case ErrorCode.ScheduleNotExist:
            return errorMessages.errorScheduleNotExist;
        case ErrorCode.UserNotExist:
            return errorMessages.errorUserNotExist;
        case ErrorCode.ImageNotExist:
            return errorMessages.errorImageNotExist;
        case ErrorCode.Error:
            return errorMessages.errorUndefinedError;
        case ErrorCode.NotScheduleTypeCalendar:
            return errorMessages.errorNotScheduleTypeCalendar;
        case ErrorCode.ReservationAlreadyAccepted:
            return errorMessages.errorReservationAlreadyAccepted;
        case ErrorCode.UserGeneralError:
            return errorMessages.errorUndefinedError;
        case ErrorCode.UserEmailAlreadyExist:
            return errorMessages.errorUserEmailAlreadyExist;
        case ErrorCode.UserEmailInMultipleInstance:
            return errorMessages.errorUserEmailInMultipleInstance;
        case ErrorCode.UserEmailChangeFailed:
            return errorMessages.errorUserEmailChangeFailed;
        case ErrorCode.WrongPasswordToken:
            return errorMessages.errorWrongPasswordToken;
        case ErrorCode.WrongPassword:
            return errorMessages.errorWrongPassword;
        case ErrorCode.WrongPasswordLength:
            return errorMessages.errorWrongPasswordLength;
        default:
            return errorMessages.errorUndefinedError;
    }
}

export const creatModifyReservationErrorMessage = (errorCode: number): MessageDescriptor => {
    switch (errorCode) {
        case ModifyReservationErrorCode.AlreadyChanged:
            return errorMessages.reservationAlreadyChanged;
        case ModifyReservationErrorCode.ReservationCollision:
            return errorMessages.reservationCollision;
        default:
            return errorMessages.errorUndefinedError;
    }
}

export const handleError = (response: IResponseDto): IHandledError => {
    const returnError: IHandledError = {};

    if (response.Error) {
        returnError.errorMessage = creatErrorMessage(response.Error.ErrorCode);
    }
    else if (response.ValidationErrors.length) {
        returnError.errorMessage = errorMessages.generalValidationError;
    }
    else {
        returnError.errorMessage = errorMessages.errorUndefinedError;
    }

    return returnError;
}


export const handleTokenError = (response: ILoginErrorDto): IHandledError => {
    const returnError: IHandledError = {};

    if (response.error === 'invalid_grant') {
        returnError.errorMessage = errorMessages.loginError;
    }
    else {
        returnError.errorMessage = errorMessages.errorUndefinedError;
    }

    return returnError;
}

export const handleModifyReservationError = (response: IResponseDto): IHandledError => {
    const returnError: IHandledError = {};

    if (response.Error) {
        returnError.errorMessage = creatModifyReservationErrorMessage(response.Error.ErrorCode);
    }
    else if (response.ValidationErrors.length) {
        returnError.errorMessage = errorMessages.generalValidationError;
    }
    else {
        returnError.errorMessage = errorMessages.errorUndefinedError;
    }

    return returnError;
}