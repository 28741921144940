import Moment from 'moment';
import Cookies from 'js-cookie';
import { ContentType } from './../fetcher';
import { IAuthInfo, ILoginRequest, IMvcLoginRequest, IRefreshRequest } from './../../entities/authorization';
import { IUserCredentials } from './../../entities/user';
import config from '../../constants';
import fetcher from '../fetcher';

const elevatedScope = 'elevated';

const getAuthInfo = (): IAuthInfo | undefined => {
  const authCookie = Cookies.get('auth');
  return typeof authCookie === 'undefined' ? undefined : JSON.parse(authCookie);
}
export const accessToken = async (): Promise<string | undefined> => {
    const authInfo = getAuthInfo();

    if (authInfo) {
        if (Moment(authInfo.expires).isAfter(Moment())) {
            return authInfo.access_token;
        } else {
            if (authInfo.refresh_token) {
                const newAuthInfo = await refreshToken(authInfo);
                if (!newAuthInfo) {
                    return;
                }
                return newAuthInfo.access_token;
            } else {
                Cookies.remove('auth');
                return;
            }
        }
    } else {
        return;
    }
}

export const fetchToken = async (credentials: IUserCredentials): Promise<IAuthInfo> => {
    const data: ILoginRequest = {
        client_id: config.API_CLIENT_ID,
        grant_type: 'password',
        username: credentials.username,
        password: credentials.password,
        keepLoggedIn: credentials.keepLoggedIn
    }

    const mvcCredential : IMvcLoginRequest = {
        userName: credentials.username,
        password: credentials.password,
        rememberMe: credentials.keepLoggedIn
    }

    const authInfo = await fetcher.post<IAuthInfo>('/Token', data, ContentType.Form);

    authInfo.username = credentials.username;
    authInfo.expires = Moment().add(authInfo.expires_in, 'second').format();

    const cookieOptions: Cookies.CookieAttributes = { secure: true, sameSite: 'none' };
    if (credentials.keepLoggedIn) {
        cookieOptions.expires = 3650;
    }
    Cookies.set('auth', JSON.stringify(authInfo), cookieOptions);

    await loginToMVC(mvcCredential);
    return authInfo;
}

export const refreshToken = async (authInfo: IAuthInfo): Promise<IAuthInfo | undefined> => {
    const data: IRefreshRequest = {
        client_id: config.API_CLIENT_ID,
        grant_type: 'refresh_token',
        username: authInfo.username,
        refresh_token: authInfo.refresh_token
    } as IRefreshRequest;

    return await fetcher.post<IAuthInfo>('/Token', data, ContentType.Form).then(returnedAuthInfo => {
        authInfo.access_token = returnedAuthInfo.access_token;
        authInfo.expires = Moment().add(returnedAuthInfo.expires_in, 'second').format();
        authInfo.refresh_token = returnedAuthInfo.refresh_token;
        Cookies.set('auth', JSON.stringify(authInfo), { secure: true, sameSite: 'none' });
        return authInfo;
    }).catch(() => {
        Cookies.remove('auth');
        return undefined;
    });
}

export const removeToken = async () => {
    Cookies.remove('auth');
    return;
}

export const isUserElevated = (): boolean => {
  const authInfo = getAuthInfo();
    return !!authInfo?.scope.includes(elevatedScope);
}

const loginToMVC = async (credentials: IMvcLoginRequest) => {
    const bodyData = JSON.stringify(credentials);

    const customHeaders = new Headers();
    customHeaders.set('Content-Type', ContentType.Json);

    const httpHeaders: RequestInit = {
        method: 'POST',
        body: bodyData,
        credentials: 'include',
        headers: customHeaders
    };

    await fetch(config.MVC_URL + '/Account/APILogin', httpHeaders)
        .catch(() => null);
};
