import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import Footer from './components/Footer/Footer';
import { CompanyCultures, ICompany, IContactInfo, FrontendType, defaultContactInfo, hasContactInfo, ICustomCssAndJs } from './entities/company';
import { IUser } from './entities/user';
import { getActivePockets, getCompanyData, getContactInfo, getCustomStyleSheetAndScript } from './services/api/company';
import { getUserWithInvoice } from './services/api/user';
import makeCancelable, { ICancelablePromise } from './services/cancelablePromise';
import smartlookClient from 'smartlook-client';
import config from './constants';
import { Helmet } from 'react-helmet';
import { IEmbedOptions, EmbedModeType, readEmbedOptions, defaultEmbedOptions } from './embed';
import AvailabilityWarning from './components/AvailabilityWarning';
import Main from './Main';
import Header from './components/Header/Header';
import { CustomScript } from './components/CustomScript/CustomScript';
import CookieConsentFooter from './components/CookieConsentFooter/CookieConsentFooter';
import { AppProvider, IAppProvidedState } from './contexts/AppContext';
import { ContactProvider } from './contexts/ContactContext';
import { EmbedProvider } from './contexts/EmbedContext';
import { IntlContext } from './contexts/IntlContext';
import { IPocket, PaymentProvider } from './entities/pocket';
import JsApiContextProvider from './contexts/JsApi/JsApiContextProvider';

export interface IAppState {
    isLoading: boolean;
    providedState: IAppProvidedState;
    contactInfo: IContactInfo | null;
    customCss: string;
    customJs: string;
    embedOptions: IEmbedOptions;
    activeBarionPockets: IPocket[];
}

class App extends React.Component<object, IAppState> {

    public state: IAppState = {
        isLoading: true,
        contactInfo: null,
        providedState: {
            company: null,
            user: null,
            isAuthenticated: undefined,
            actions: {
                setUser: (userData: IUser | null) => this.setUser(userData),
                setLoading: (isLoading: boolean) => this.setLoading(isLoading),
            }
        },
        customCss: '',
        customJs: '',
        embedOptions: defaultEmbedOptions,
        activeBarionPockets: []
    };

    public companySubscription: ICancelablePromise<ICompany>;
    public userSubscription: ICancelablePromise<IUser | null>;
    public contactInfoSubscription: ICancelablePromise<IContactInfo>;
    public activePocketsSubscription: ICancelablePromise<IPocket[]>;
    private customCssAndJsSubscription: ICancelablePromise<ICustomCssAndJs>;

    public componentDidMount() {
        if (config.SMARTLOOK_CODE) {
            try {
                smartlookClient.init(config.SMARTLOOK_CODE);
            } catch (error) {
                // Ignore smartlook init error 
            };
        }

        this.setState({ embedOptions: readEmbedOptions() });

        this.companySubscription = makeCancelable<ICompany>(getCompanyData());
        this.customCssAndJsSubscription = makeCancelable<ICustomCssAndJs>(getCustomStyleSheetAndScript());

        Promise.all([this.companySubscription.promise,  this.customCssAndJsSubscription.promise]).then(([company, cssJsData]) => {
            this.setState({
                providedState: {
                    ...this.state.providedState,
                    company
                }
            });

            const context = this.context;

            if (!context.alreadySwitched) {
                const urlSearchParams = new URLSearchParams(window.location.search);
                const cultureParam = urlSearchParams.get('culture') || urlSearchParams.get('Culture');
                const formalParam = urlSearchParams.get('formal') || urlSearchParams.get('Formal');

                const isFormal = formalParam === null ? company.IsFormalCulture : formalParam === 'true';
                const culture = cultureParam === null ? CompanyCultures[company.DefaultUserCulture] : cultureParam;

                if (culture !== context.locale || isFormal !== context.isFormal) {
                    context.setLocale(culture, isFormal);
                }
            }

            if (cssJsData) {
                const customCss: string = cssJsData.StyleSheet;
                const customJs: string = cssJsData.Script;
                this.setState({
                    customCss,
                    customJs
                });
            }
        })
        .finally(() => this.setLoading(false))
        .catch(() => null);

        this.userSubscription = makeCancelable<IUser | null>(getUserWithInvoice());
        this.userSubscription.promise.then(user => {
            this.setUser(user);
        }, () => null);

        this.contactInfoSubscription = makeCancelable<IContactInfo>(getContactInfo());
        this.contactInfoSubscription.promise.then(contactInfo => {
            this.setState({
                contactInfo
            })
        }, () => null);

        this.activePocketsSubscription = makeCancelable<IPocket[]>(getActivePockets());
        this.activePocketsSubscription.promise.then(activePockets => {
            const activeBarionPockets = activePockets.filter(pocket => pocket.PaymentProvider === PaymentProvider.Barion)
            this.setState({
                activeBarionPockets
            });
        }, () => null);
    }

    public componentWillUnmount() {
        this.customCssAndJsSubscription?.cancel();
        this.companySubscription?.cancel();
        this.userSubscription?.cancel();
        this.contactInfoSubscription?.cancel();
        this.activePocketsSubscription?.cancel();
    }

    public setUser = (userData: IUser | null) => this.setState({
        providedState: {
            ...this.state.providedState,
            user: userData,
            isAuthenticated: !!userData
        }
    });

    public setLoading = (isLoading: boolean) => this.setState({
        isLoading
    });

    public render() {
        const { contactInfo, customCss, customJs, embedOptions, activeBarionPockets } = this.state;
        const { company } = this.state.providedState;

        const isEmbedded = embedOptions.mode !== EmbedModeType.WebPage;

        if (company && company.FrontendType === FrontendType.Inactive) {
            return <AvailabilityWarning type="inactiveFE" modifier="app-level" />;
        }

        return (
            <>
                <Helmet>
                    {company && <title>{company.Name}</title>}
                    {!!customCss && <link rel="stylesheet" href={customCss} />}
                    <link rel="canonical" href={`${window.location.protocol}//${window.location.hostname}/`} />
                </Helmet>
                <CustomScript scriptText={customJs} />
                <BrowserRouter basename="/public">
                    <CookieConsentFooter
                        barionPixelIds={activeBarionPockets
                            .filter((pocket) => pocket.BarionPixelId !== null)
                            .map((pocket) => pocket.BarionPixelId!)} />
                    <div className={`App ${isEmbedded ? 'App--embedded' : ''}`} >
                        <div className="App__loading-bar">
                            <BarLoader loading={this.state.isLoading} color="var(--primary-select-color)" width="100%" height={6} />
                        </div>
                        <AppProvider value={this.state.providedState}>
                            <ContactProvider value={this.state.contactInfo || defaultContactInfo}>
                                <EmbedProvider value={this.state.embedOptions}>
                                    {!isEmbedded &&
                                        <Header company={company} hasContactInfo={hasContactInfo(contactInfo)} />
                                    }
                                    <JsApiContextProvider>
                                        <Main
                                            isEmbedded={isEmbedded}
                                            hideUser={embedOptions.hideUser}
                                            company={company}
                                            isAuthenticated={this.state.providedState.isAuthenticated}
                                            contactInfo={this.state.contactInfo}
                                            {...this.state.providedState.actions}
                                        />
                                    </JsApiContextProvider>
                                    {embedOptions.hideFooter ||
                                        <Footer
                                            isEmbedded={isEmbedded}
                                            hasActiveBarionPocket={(!!activeBarionPockets?.length)}
                                        />
                                    }
                                </EmbedProvider>
                            </ContactProvider>
                        </AppProvider>
                    </div>
                </BrowserRouter>
            </>
        );
    }
}

App.contextType = IntlContext;

export default App;
