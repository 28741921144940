import React, { useContext } from 'react';
import { ICompany } from 'src/entities/company';
import { IUser } from 'src/entities/user';
import { ILoader } from 'src/services/loader';

export interface IAppProvidedActions extends ILoader {
    setUser: (userData: IUser | null) => void,
}

export interface IAppProvidedState {
    company: ICompany | null;
    user: IUser | null;
    isAuthenticated?: boolean;
    actions: IAppProvidedActions;
}

export const AppContext = React.createContext<IAppProvidedState>({
    company: null,
    user: null,
    actions: {
        setLoading: () => undefined,
        setUser: () => undefined
    }
});

export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;
export const useAppContext = () => {
  return useContext(AppContext);
};