import React from 'react';
import { Routes } from './routes';
import { ICompany, defaultRequiredInfo, IContactInfo } from './entities/company';
import { Route, Routes as RoutesContainer } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { IAppProvidedActions } from './contexts/AppContext';
import JsApi from './components/JsApi/JsApi';
import RequireAuth from './components/RequireAuth/RequireAuth';
import Root from './components/Root/Root';

const UserDetails = lazy(
    () => import(/* webpackChunkName: "UserDetails" */ './components/UserDetails/UserDetails')
);
const Reservations = lazy(
    () => import(/* webpackChunkName: "Reservations" */ './components/Reservations/Reservations')
);
const ChangePassword = lazy(
    () => import(/* webpackChunkName: "ChangePassword" */ './components/Password/ChangePassword')
);
const ModifyReservation = lazy(
    () =>
        import(
            /* webpackChunkName: "ModifyReservation" */ './components/ModifyReservation/ModifyReservation'
        )
);
const PreReservation = lazy(
    () =>
        import(
            /* webpackChunkName: "PreReservation" */ './components/PreReservation/PreReservation'
        )
);
const Login = lazy(() => import(/* webpackChunkName: "Login" */ './components/Login/Login'));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ './components/Signup/Signup'));
const ForgotPassword = lazy(
    () => import(/* webpackChunkName: "ForgotPassword" */ './components/Password/ForgotPassword')
);
const PasswordReset = lazy(
    () => import(/* webpackChunkName: "PasswordReset" */ './components/Password/PasswordReset')
);
const ReservationConfirm = lazy(
    () =>
        import(
            /* webpackChunkName: "ReservationConfirm" */ './components/BookingFlow/ReservationConfirm/ReservationConfirm'
        )
);
const Home = lazy(() => import(/* webpackChunkName: "Home" */ './components/Home/Home'));
const OverviewCalendar = lazy(
    () =>
        import(
            /* webpackChunkName: "OverviewCalendar" */ './components/OverviewCalendar/OverviewCalendar'
        )
);
const PaymentProcessing = lazy(
    () =>
        import(
            /* webpackChunkName: "UnsuccessfulPayment" */ './components/Payment/PaymentProcessing/PaymentProcessing'
        )
);
const PaymentProcessPending = lazy(
    () =>
        import(
            /* webpackChunkName: "UnsuccessfulPayment" */ './components/Payment/PaymentProcessPending/PaymentProcessPending'
        )
);

interface IMainProps extends IAppProvidedActions {
    isEmbedded: boolean;
    hideUser: boolean;
    company: ICompany | null;
    isAuthenticated?: boolean;
    contactInfo: IContactInfo | null;
}

const Main: React.FC<IMainProps> = ({
    isEmbedded,
    hideUser,
    company,
    setLoading,
    setUser,
    contactInfo,
    isAuthenticated,
}) => (
    <div className="App__container">
        <JsApi />
        <Suspense fallback={null}>
            <RoutesContainer>
                <Route
                    path={Routes.HomeSlash}
                    element={<Root isEmbedded={isEmbedded} hideUser={hideUser} company={company} />}
                >
                    <Route
                        path={Routes.UserReservations}
                        element={<RequireAuth element={<Reservations setLoading={setLoading} />} />}
                    />
                    <Route
                        path={Routes.UserDetails}
                        element={<RequireAuth element={<UserDetails />} />}
                    />
                    <Route
                        path={Routes.ChangePassword}
                        element={
                            <RequireAuth element={<ChangePassword setLoading={setLoading} />} />
                        }
                    />
                    <Route
                        path={Routes.ModifyReservation}
                        element={
                            <ModifyReservation
                                setLoading={setLoading}
                                isAuthenticated={isAuthenticated}
                                requiredFieldInfo={
                                    company?.RequiredFieldInfo || defaultRequiredInfo
                                }
                            />
                        }
                    />
                    <Route
                        path={Routes.PreReservation}
                        element={
                            <PreReservation
                                setLoading={setLoading}
                                requiredFieldInfo={
                                    company?.RequiredFieldInfo || defaultRequiredInfo
                                }
                            />
                        }
                    />
                    <Route
                        path={Routes.UserReservationsByEmail}
                        element={<Reservations setLoading={setLoading} />}
                    />
                    <Route path={Routes.Login} element={<Login setUser={setUser} />} />
                    <Route path={Routes.Register} element={<Signup setLoading={setLoading} />} />
                    <Route path={Routes.ForgotPassword} element={<ForgotPassword />} />
                    <Route
                        path={Routes.PasswordReset}
                        element={<PasswordReset setLoading={setLoading} />}
                    />
                    <Route
                        path={Routes.Confirmation}
                        element={company ? <ReservationConfirm company={company} /> : null}
                    />
                    {[Routes.Book, Routes.ReservationEnd, Routes.HomeSlash, Routes.Home].map(
                        (route) => (
                            <Route
                                path={route}
                                element={<Home company={company} contactInfo={contactInfo} />}
                                key={route}
                            />
                        )
                    )}
                    <Route path={Routes.OverviewCalendar} element={<OverviewCalendar />} />
                    <Route path={Routes.PaymentProcessing} element={<PaymentProcessing />} />
                    <Route
                        path={Routes.PaymentProcessPending}
                        element={<PaymentProcessPending />}
                    />
                </Route>
            </RoutesContainer>
        </Suspense>
    </div>
);

export default Main;
